import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";

type SortingValueType = string | Classification | Category;

export const commonDefs = {
  suppressHeaderMenuButton: true,
  mainMenuItems: [
    "sortAscending",
    "sortDescending",
    "autoSizeThis",
    "autoSizeAll",
    "columnChooser",
    "resetColumns",
  ],
};

export const amountComparator = (valueA: number, valueB: number) => {
  return valueA - valueB;
};

export const amountValueGetter = (params: any) => {
  if (params.data?.quantity) {
    return params.data.quantity.actualQuantity.amount;
  }
};

export const sortingComparator = (
  valueA: SortingValueType,
  valueB: SortingValueType,
  nodeA: any,
  nodeB: any
) => {
  if (typeof valueA === "string" && typeof valueB === "string") {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  }

  if (typeof valueA === "object" && typeof valueB === "object") {
    const valueAName = getObjVal(valueA).name;
    const valueBName = getObjVal(valueB).name;
    return valueAName.toLowerCase().localeCompare(valueBName.toLowerCase());
  }
};

const getObjVal = (value: Record<string, any>) => {
  return value[0];
};
