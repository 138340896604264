import { Injectable, Injector } from "@angular/core";

import { Scenario } from "@bitwarden/web-vault/app/models/data/blobby/scenario.data";
import { ScenarioResponse } from "@bitwarden/web-vault/app/models/data/response/scenario.response";
import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import {
  GroupScenarioBalance,
  ScenarioOptionWinner,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { CalculationServiceAbstraction } from "@bitwarden/web-vault/app/services/DataCalculationService/calculation.service.abstraction";
import { ScenarioOption } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/scenario-option";
import { ScenarioOptionsFactory } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/scenario-options-factory";
import { WebWorkerQueue } from "@bitwarden/web-vault/app/services/web-worker/WebWorkerQueue";

import { MainProgressBar } from "../../../services/progress-bar/main-progress-bar";

@Injectable({
  providedIn: "root",
})
export class ScenarioService implements CalculationServiceAbstraction {
  scenarioWinners: GroupScenarioBalance[] = [];
  scenariosCompleted = 0;
  callback: (scenarioWinners: GroupScenarioBalance[]) => Promise<void>;
  scenarios: Scenario[] = [];
  scenarioOptions: ScenarioOption[] = [];
  scenarioGroup: ScenarioGroup;
  webWorkerQueue: WebWorkerQueue;
  completedPermutations = 0;
  totalPermutations = 0;

  progressCallback: (action: string, progressPercentage: number) => void;

  constructor(private injector: Injector, private mainProgressBar: MainProgressBar) {
    this.webWorkerQueue = new WebWorkerQueue();
  }

  async runScenarioGroup(
    scenarioGroup: ScenarioGroup,
    callback: (scenarioWinners: GroupScenarioBalance[]) => Promise<void>,
    progressCallback: (action: string, progressPercentage: number) => void
  ) {
    this.callback = callback;
    this.progressCallback = progressCallback;

    this.resetScenarios();

    let scenarioIndex = 0;
    for (const s of scenarioGroup.scenarios) {
      const scenario = new Scenario(new ScenarioResponse(s));
      this.scenarios.push(scenario);
      if (new Date(scenarioGroup.endDate.date) > new Date(scenarioGroup.anchorPoint.date)) {
        await this.setupScenarios(scenario, scenarioGroup);
      } else {
        // if the endDate filter is before the anchor point, then we don't need to run the scenario
        await this.completeScenario(scenario, scenarioIndex, scenarioGroup, {
          createdRecords: {},
          groupedBalance: null,
        });
      }
      scenarioIndex++;
    }

    this.setProgress(0);
    if (new Date(scenarioGroup.endDate.date) > new Date(scenarioGroup.anchorPoint.date)) {
      scenarioIndex = 0;
      for (const scenario of this.scenarios) {
        if (new Date(scenarioGroup.endDate.date) > new Date(scenarioGroup.anchorPoint.date)) {
          const scenarioOption = this.scenarioOptions[scenarioIndex];
          await scenarioOption.run(
            this.completeScenario.bind(this, scenario, scenarioIndex, scenarioGroup),
            this.completePermutation.bind(this)
          );
        }
        scenarioIndex++;
      }
    }
  }

  async setupScenarios(scenario: Scenario, scenarioGroup: ScenarioGroup) {
    const scenarioOption = ScenarioOptionsFactory.createScenarioOption(
      scenario.scenarioType,
      scenarioGroup,
      this.injector,
      this.webWorkerQueue
    );

    this.scenarioOptions.push(scenarioOption);

    if (new Date(scenarioGroup.endDate.date) > new Date(scenarioGroup.anchorPoint.date)) {
      await scenarioOption.beforeRunningPermutationsHook();
    }

    if (scenarioOption.permutations.length > 0) {
      this.totalPermutations += scenarioOption.permutations.length;
    }
  }

  resetScenarios() {
    this.scenarioWinners = [];
    this.scenarios = [];
    this.scenarioOptions = [];
    this.scenariosCompleted = 0;
    this.totalPermutations = 0;
    this.completedPermutations = 0;
  }

  async completeScenario(
    scenario: Scenario,
    scenarioIndex: number,
    scenarioGroup: ScenarioGroup,
    groupedBalanceByScenario: ScenarioOptionWinner
  ) {
    this.scenariosCompleted++;

    this.scenarioWinners[scenarioIndex] = {
      ScenarioGroupId: scenarioGroup.id,
      scenarioID: scenario.id,
      scenarioType: scenario.scenarioType,
      scenarioName: scenario.name,
      createdRecords: groupedBalanceByScenario.createdRecords,
      groupedBalance: groupedBalanceByScenario.groupedBalance,
      graphData: null,
      helpInfo: groupedBalanceByScenario.helpInfo,
    };

    if (this.scenariosCompleted === scenarioGroup.scenarios.length) {
      await this.callback(this.scenarioWinners);
    }
  }

  completePermutation() {
    this.completedPermutations++;
    const percentage = Math.round(
      this.totalPermutations === 0 ? 0 : (this.completedPermutations / this.totalPermutations) * 100
    );
    this.setProgress(percentage);
    this.mainProgressBar.setLoadingPercentage(percentage);
    this.mainProgressBar.setProgressLabel(`${percentage}% complete`);
  }

  setProgress(progressPercentage: number) {
    const action = "Calculating scenarios";
    this.progressCallback(action, progressPercentage);
  }
}
