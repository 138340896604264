<div class="tw-rounded-lg tw-bg-white tw-p-4 sm:tw-mx-0 sm:tw-my-0 sm:tw-min-h-[90vh]">
  <nav
    class="tw-relative tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-gap-4"
    (click)="redirectToSettings()"
  >
    <div class="md:tw-hidden">
      <svg class="tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#left-arrow" />
      </svg>
    </div>
    <div
      class="tw-hidden tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-bg-neutral-50 tw-shadow-button md:tw-flex"
    >
      <svg class="tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#left-arrow" />
      </svg>
    </div>
    <span class="tw-text-xl tw-font-semibold tw-text-neutral-800 md:tw-hidden">{{
      "access" | i18n
    }}</span>
    <div class="tw-absolute tw-hidden tw-w-full tw-items-center tw-justify-center md:tw-flex">
      <span class="tw-text-xl tw-font-semibold tw-text-neutral-800">{{ "access" | i18n }}</span>
    </div>
  </nav>

  <div class="tw-mt-6 tw-mb-3 tw-text-2xl tw-font-medium sm:tw-mb-1.5 sm:tw-text-lg">
    {{ "currentAccess" | i18n }}
  </div>
  <div
    *ngIf="!isPremiumUser"
    class="tw-w-[84px] tw-rounded-full tw-bg-neutral-50 tw-py-3 tw-text-center tw-text-lg tw-font-medium tw-text-primary sm:tw-mb-6 sm:tw-w-[67px] sm:tw-py-2 sm:tw-text-base"
  >
    {{ "accessStatus1" | i18n }}
  </div>

  <div
    *ngIf="isPremiumUser"
    class="tw-flex tw-h-fit tw-w-fit tw-items-center tw-justify-center tw-rounded-full tw-bg-neutral-50 tw-px-4 tw-text-lg tw-font-medium tw-text-primary sm:tw-text-base"
  >
    <svg class="tw-mt-2 tw-h-10 tw-w-10">
      <use xlink:href="images/sprite.svg#vector" />
    </svg>
    <span class="tw-whitespace-nowrap tw-text-neutral-800">{{ "accessStatus2" | i18n }}</span>
  </div>

  <section class="tw-my-8 sm:tw-my-4">
    <span class="tw-text-[18.2px] tw-font-medium sm:tw-text-base">{{
      "accessStatus1" | i18n
    }}</span>
    <p
      class="tw-mt-2 tw-rounded-lg tw-border tw-border-solid tw-border-neutral-100 tw-px-6 tw-py-4 sm:tw-px-3 sm:tw-py-2"
    >
      {{ "accessStatus1Content1" | i18n }} <br />{{ "accessStatus1Content2" | i18n }}
    </p>
  </section>
  <section class="tw-my-8 sm:tw-my-4">
    <span class="tw-text-[18.2px] tw-font-medium sm:tw-text-base">{{
      "accessStatus2" | i18n
    }}</span>
    <div
      class="tw-mt-2 tw-rounded-lg tw-border tw-border-solid tw-border-neutral-100 tw-px-6 tw-py-4 sm:tw-px-3 sm:tw-py-2"
    >
      <div class="tw-flex">
        <svg class="tw-mr-1 tw-h-10 tw-w-10 tw-font-semibold">
          <use xlink:href="images/sprite.svg#vector" />
        </svg>
        <h3 class="tw-mt-1">{{ "accessStatus2" | i18n }}</h3>
      </div>
      <p class="tw-mb-0">
        {{ "accessStatus2Content1" | i18n }} <br />{{ "accessStatus2Content2" | i18n }} <br />{{
          "accessStatus2Content3" | i18n
        }}
      </p>
      <button
        *ngIf="!isPremiumUser"
        class="tw-my-3 tw-mb-2 tw-rounded-full tw-border-0 tw-bg-primary tw-py-3 tw-px-7 tw-font-medium tw-shadow-button hover:!tw-text-white sm:tw-py-2 sm:tw-px-5"
      >
        {{ "requestAccessBtn" | i18n }}
        <svg class="tw-ml-1 tw-h-5 tw-w-5"><use xlink:href="images/sprite.svg#hyperlink" /></svg>
      </button>
    </div>
  </section>
</div>
