import { CurrencyPipe } from "@angular/common";
import {
  Component,
  ChangeDetectorRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  HostListener,
  DoCheck,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subject, take } from "rxjs";
import { takeUntil } from "rxjs/operators";

import "./transaction-table-tabs.scss";
import { AccordionComponent } from "@bitwarden/web-vault/app/components/accordion/accordion.component";
import { ScenarioDifferenceMessageComponent } from "@bitwarden/web-vault/app/components/scenario-difference-message/scenario-difference-message.component";
import { glossRouteInfo } from "@bitwarden/web-vault/app/layouts/sections-info-texts";
import { GlossRouteEnum } from "@bitwarden/web-vault/app/models/enum/navigation.enum";
import { GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";
import {
  ScenarioAccountInfo,
  ScenarioData,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { SharedService } from "@bitwarden/web-vault/app/shared/sharedAppEvent.service";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper.common";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

@Component({
  selector: "app-transaction-table-tabs",
  templateUrl: "transaction-table-tabs.component.html",
  styles: ["transaction-table-tabs.scss"],
  providers: [CurrencyPipe],
})
export class TransactionTableTabsComponent implements DoCheck {
  @ViewChild("accordionContainer", { read: ViewContainerRef }) accordionContainer: ViewContainerRef;
  inDevMode = HelperCommon.isDevEnv();
  @Output() linkConversion = new EventEmitter<any>();
  @Output() linkTransfer = new EventEmitter<any>();
  @Output() unlinkTransactions = new EventEmitter<any>();
  @Output() deleteTransaction = new EventEmitter<any>();
  @Output() resetColumns = new EventEmitter<any>();

  @Input() isLinkButtonEnabled: boolean;

  private destroy$ = new Subject<void>();
  tabs: Array<{ key: number; content: string; type: string; class: string }>;
  graphData: Array<GraphDataSet>;
  scenarioData: ScenarioData;
  dashboardService: DashboardService;
  hasTransactions = false;

  tooltipInfo: string;
  showHelpTooltip = false;
  xPosition: number;
  yPosition: number;
  pointer: string;

  trianglePointerOffset = 50;
  trianglePointerHeight = 16;

  differenceScenarioRef: MatDialogRef<ScenarioDifferenceMessageComponent>;

  selectedTabKey: string | null = null;

  constructor(
    private injector: Injector,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private currencyPipe: CurrencyPipe,
    protected changeDetectorRef: ChangeDetectorRef,
    private dateFormat: DateFormat
  ) {
    this.dashboardService = this.injector.get(DashboardService);
    this.subscribeToDashboardObservables();
  }

  // TODO change this to fromEvent to handle the button click
  ngDoCheck() {
    if (localStorage.getItem("startTutorial") === "true") {
      this.openScenarioDifferenceMessage();
      localStorage.removeItem("startTutorial");
    }
  }

  /**
   todo this is a workaround for the non-binding of the event of the Accordion component
   this file needs to be refactored to not inject html
  **/
  @HostListener("click", ["$event"])
  onClickEvent(event: any) {
    event.preventDefault();
    event.stopPropagation();
    const id = event.target.parentElement.parentElement.parentElement.id;
    if (id.includes("accordion")) {
      this.toggleAccordion(id);
    }
  }

  private toggleAccordion(id: string) {
    const element = document.getElementById(id);
    const accordionContent = (element as HTMLElement).lastElementChild as HTMLElement;
    const icon = element.querySelector("svg").lastElementChild as SVGUseElement;
    const isDisplayed =
      accordionContent.style.display === "none" || accordionContent.style.display === "";
    const currentIcon = isDisplayed ? "down" : "up";
    const newIcon = isDisplayed ? "up" : "down";
    icon.href.baseVal = String(icon.href.baseVal).replace(currentIcon, newIcon);
    accordionContent.style.display = isDisplayed ? "block" : "none";
  }
  /** end of this todo **/

  /** todo refactor this file to not inject html **/
  private generateAccordionHtml(index: number, account: ScenarioAccountInfo): void {
    const accountName = this.getAccount(account);
    const link = this.getUrl(account);
    const linkToBank = this.generateLinkToBank(link);
    const rate = this.getRate(account);
    const institutionName = this.getInstitutionName(account);
    const lastCheckedDate = this.getLastCheckedDate(account);
    const accordionTitle = `${institutionName} ${accountName} ${rate}`;
    const accordionContent = this.getAccordionContent(linkToBank, lastCheckedDate);
    const newAccordionContainerRef = this.accordionContainer.createComponent(AccordionComponent);
    newAccordionContainerRef.instance.id = `accordion-${index}`;
    newAccordionContainerRef.instance.title = accordionTitle;
    newAccordionContainerRef.instance.content = `<div class="tw-flex tw-flex-col tw-p-2">${accordionContent}</div>`;
    this.changeDetectorRef.detectChanges();
    this.accordionContainer.clear(); // remove the component from the DOM
    return newAccordionContainerRef.location.nativeElement.outerHTML;
  }

  tabName(tab: { key: number; content: string; type: string; class: string }) {
    if (this.tabs.length > 3 && this.isMobile() && !tab.class.includes("selected")) {
      return tab.content.slice(0, 3) + "..." + (tab.type === "transaction" ? "" : tab.key + 1);
    } else {
      return tab.content;
    }
  }

  tabContainerByDevice(tab: { key: number; content: string; type: string; class: string }) {
    if (this.isDesktop()) {
      return tab.class + " inDesktop";
    }
    if (this.isMobile()) {
      return tab.class;
    }
    if (this.isTablet()) {
      return tab.class;
    }
  }

  tabStyle(tab: { key: number; content: string; type: string; class: string }, elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      this.renderer.setStyle(element, "width", 100);
    }
    if (this.isDesktop() || (this.isMobile() && tab.class.includes("selected"))) {
      return tab.class;
    }
    return tab.class;
  }

  // shining effect on scenario 1 tooltip
  highlightScenarioButton(scenarioNumber: number) {
    const scenarioButton = document.getElementById(`scenario-${scenarioNumber}-help-button`);

    if (scenarioButton) {
      scenarioButton.classList.add("tw-animate-ping", "opacity-100");

      const removeHighlight = () => {
        scenarioButton.classList.replace("tw-animate-ping", "bg-transparent");
        scenarioButton.classList.replace("opacity-100", "opacity-25"); // Adjust as needed
        scenarioButton.removeEventListener("click", removeHighlight); // Clean up the event listener
      };

      scenarioButton.addEventListener("click", removeHighlight);
    }
  }

  async subscribeToDashboardObservables() {
    this.dashboardService.graphData$.pipe(takeUntil(this.destroy$)).subscribe((graphData) => {
      this.graphData = this.dashboardService.isVaultPurge ? [] : graphData;
      this.buildTabData(false);
    });

    this.dashboardService.scenarioData$.pipe(takeUntil(this.destroy$)).subscribe((scenarioData) => {
      this.scenarioData = this.dashboardService.isVaultPurge
        ? { balance: [], scenario: [] }
        : scenarioData;
      if (this.scenarioData.scenario.length > 0) {
        this.graphData = this.scenarioData.balance;
        this.buildTabData(true);
      }
    });
  }

  openScenarioDifferenceMessage() {
    const dialogRef = this.dialog.open(ScenarioDifferenceMessageComponent, {
      maxWidth: "800px",
      data: { scenarioData: this.scenarioData },
      disableClose: true,
      panelClass: ["scenario-difference-dialog", "xs:tw-h-full"],
      autoFocus: false,
    });
    this.differenceScenarioRef = dialogRef;
    // Use Renderer2 to add the class to the mat-dialog-container element
    dialogRef
      .afterOpened()
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe(() => {
        const matDialogContainer = document.querySelector("mat-dialog-container");
        if (matDialogContainer && this.isMobile()) {
          this.renderer.addClass(matDialogContainer, "mobile-mat-dialog-container");
        } else {
          this.renderer.addClass(matDialogContainer, "desktop-mat-dialog-container");
        }
      });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result !== "skip") {
          this.clickOnScenario3Button();
        }
      });
  }

  // todo: needs improvement but logic is not use set timeout
  clickOnScenario3Button() {
    const onboardingStartPoint = document.getElementById("scenario-3-help-button");
    if (onboardingStartPoint) {
      onboardingStartPoint.scrollIntoView({ behavior: "smooth", block: "center" });
      setTimeout(() => {
        const helpButtonForScenario3 = document.getElementById("scenario-3-help-button");
        if (helpButtonForScenario3) {
          helpButtonForScenario3.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
            })
          );
        }
        localStorage.removeItem("startTutorial");
      }, 500);
    }
    this.highlightScenarioButton(1);
  }

  async updateTab(type: string, key: number) {
    // update the scenario tab on the dashboard service
    await this.dashboardService.updateTransactionsTable(type, key);

    this.sharedService.updateGridTabType(type);

    for (const key in this.tabs) {
      this.tabs[key].class = "tabs";
    }

    if (type === "transaction") {
      this.tabs[0].class = "tabs selected";
    } else if (this.hasTransactions) {
      this.tabs[key + 1].class = "tabs selected";
    } else {
      this.tabs[key].class = "tabs selected";
    }
  }

  async openTabHelp(e: MouseEvent, type: string, key: number) {
    this.tooltipInfo = this.getScenarioHelpText(type, key);
    this.showHelpTooltip = true;

    const target = e.currentTarget as HTMLElement;
    const targetRect = target.getBoundingClientRect();

    this.pointer = "bottom";
    this.xPosition = targetRect.right - this.trianglePointerOffset - targetRect.width / 2;
    this.yPosition = targetRect.top - this.trianglePointerHeight;
  }

  onTooltipClose() {
    this.showHelpTooltip = false;
  }

  private buildTabData(hasScenario: boolean) {
    const tabData = [];

    const transactionTab = {
      content: "Transactions",
      type: "transaction",
      key: 0,
      class: "tabs transaction-tab",
    };

    this.hasTransactions = false;
    if (!hasScenario) {
      tabData.push(transactionTab);
    } else {
      if (this.graphData.length > 0) {
        tabData.push(transactionTab);
        this.hasTransactions = true;
      }

      for (let i = 0; i < this.scenarioData.scenario.length; i++) {
        const isSelected = i === 2;
        const scenarioTab = {
          content: "Path " + (i + 1),
          type: "scenario",
          key: i,
          class: isSelected ? "tabs selected" : "tabs",
          id: `scenario-${i + 1}-tab`,
        };
        tabData.push(scenarioTab);
      }
    }
    this.tabs = tabData;
  }

  private getScenarioHelpText(type: string, key: number) {
    // extract the scenario help text from
    const helpInfo = glossRouteInfo[GlossRouteEnum.dashboard];
    let scenarioHelp;
    if (typeof helpInfo === "object") {
      if (helpInfo?.scenarioHelp) {
        scenarioHelp = helpInfo.scenarioHelp;
      }
    }
    let helpText = "";
    if (type === "scenario") {
      helpText += scenarioHelp[key];
    }
    helpText += this.getScenarioDetails(type, key);

    return helpText;
  }

  private getAccordionContent(linkToBank: string, lastCheckedDate: string) {
    const finalFormattedDate = lastCheckedDate
      ? this.dateFormat.toDateWithRelativeDays(lastCheckedDate)
      : null;
    const linkToSiteElement = `<span>Link to site ${linkToBank}</span>`;

    return finalFormattedDate
      ? `<span>Last reviewed from site: ${finalFormattedDate}</span><br/>${linkToSiteElement}`
      : linkToSiteElement;
  }

  private getScenarioDetails(type: string, key: number) {
    let scenarioText = "";
    if (
      this.scenarioData &&
      this.scenarioData.scenario.length > 0 &&
      this.scenarioData.scenario?.[key]
    ) {
      const scenarioDetails = this.scenarioData.scenario[key];
      if (scenarioDetails?.helpInfo) {
        scenarioText += `<div class='scenario-details'>`;
        if (scenarioDetails.helpInfo?.transferAmount) {
          const formattedAmount = this.currencyPipe.transform(
            scenarioDetails.helpInfo.transferAmount,
            "AUD",
            "symbol"
          );
          scenarioText +=
            `<div class="scenario-detail"><div class="scenario-help-heading short">Total Transfer Amount</div>` +
            `<div class="scenario-info-grouping short">` +
            `<div class="scenario-help-info">${formattedAmount}</div>` +
            `</div></div>`;
        }
        if (scenarioDetails.helpInfo?.transferToAccount) {
          const accountName = this.getAccount(scenarioDetails.helpInfo?.transferToAccount);
          const rate = this.getRate(scenarioDetails.helpInfo?.transferToAccount);
          const institutionName = this.getInstitutionName(
            scenarioDetails.helpInfo?.transferToAccount
          );
          scenarioText +=
            `<div class="scenario-detail"><div class="scenario-help-heading short">Transfer to</div>` +
            `<div class="scenario-info-grouping long">` +
            `<div class="scenario-help-info">${institutionName} ${accountName} ${rate}</div>` +
            `</div></div>`;
        }
        if (
          scenarioDetails.helpInfo?.transferFromAccount &&
          scenarioDetails.helpInfo.transferFromAccount.length > 0
        ) {
          const existingAccounts = scenarioDetails.helpInfo.transferFromAccount.length;
          scenarioText +=
            `<div class="scenario-detail"><div class="scenario-help-heading short">Transfer From</div>` +
            `<div class="scenario-info-grouping long">` +
            `<div class="tw-flex tw-justify-between">` +
            `<div>${existingAccounts} Existing Accounts</div>` +
            `</div>`;
          for (const [index, account] of scenarioDetails.helpInfo.transferFromAccount.entries()) {
            scenarioText += this.generateAccordionHtml(index, account);
          }
          scenarioText += `</div></div>`;
        }
        if (scenarioDetails.helpInfo?.totalBalance) {
          const totalBalance = scenarioDetails.helpInfo.totalBalance;
          const formattedBalance = this.currencyPipe.transform(totalBalance, "AUD", "symbol");
          scenarioText +=
            `<div class="scenario-detail"><div class="scenario-help-heading short">Total Current Balance</div>` +
            `<div class="scenario-info-grouping long">` +
            `<div class="scenario-help-info">${formattedBalance}</div>` +
            `</div></div>`;
        }
        if (scenarioDetails.helpInfo?.existingAccounts) {
          const totalAccounts = scenarioDetails.helpInfo?.existingAccounts.length;
          scenarioText +=
            `<div class="scenario-detail"><div class="scenario-help-heading short">Current Account</div>` +
            `<div class="scenario-info-grouping long">` +
            `<div>` +
            `<div>${totalAccounts} Existing Accounts</div>` +
            `</div>`;
          for (const [index, account] of scenarioDetails.helpInfo.existingAccounts.entries()) {
            scenarioText += this.generateAccordionHtml(index, account);
          }
          scenarioText += `</div></div>`;
        }
        scenarioText += `</div>`;
      }
    }
    return scenarioText;
  }

  generateLinkToBank(link: string) {
    const bankLink: string = link ? link : `#`;
    const newTab: string = link ? `target="_blank"` : ``;
    return `<a href="${bankLink}" ${newTab}>
        <svg class="tw-h-5 tw-w-5">
           <use xlink:href="images/sprite.svg#link" />
        </svg>
      </a>`;
  }

  getInstitutionName(accountInfo: ScenarioAccountInfo) {
    return accountInfo?.accountBank ? accountInfo.accountBank : "";
  }

  getUrl(accountInfo: ScenarioAccountInfo) {
    return accountInfo?.accountUrl ? accountInfo.accountUrl : "";
  }

  getAccount(accountInfo: ScenarioAccountInfo) {
    return accountInfo?.accountName ? accountInfo.accountName : "";
  }

  getLastCheckedDate(accountInfo: ScenarioAccountInfo) {
    return accountInfo?.rate ? accountInfo.rate[0].last_updated : "";
  }

  getRate(accountInfo: ScenarioAccountInfo) {
    if (accountInfo.rate && accountInfo.rate.length > 0) {
      const maxRate = accountInfo.rate.reduce((max, current) => {
        return current.rate > max ? current.rate : max;
      }, accountInfo.rate[0].rate);
      const minRate = accountInfo.rate.reduce((min, current) => {
        return current.rate < min ? current.rate : min;
      }, accountInfo.rate[0].rate);

      const rate = accountInfo.rate.length > 1 ? `${minRate} - ${maxRate}` : `${maxRate}`;
      return `(${rate}% p.a.)`;
    } else {
      return "(unknown account type)";
    }
  }

  ngOnDestroy() {
    this.showHelpTooltip = false;
    this.destroy$.next();
    this.destroy$.complete();
  }

  callLinkConversion() {
    this.linkConversion.emit();
  }

  callDeleteTransaction() {
    this.deleteTransaction.emit();
  }

  callLinkTransfer() {
    this.linkTransfer.emit();
  }

  callUnlinkTransactions() {
    this.unlinkTransactions.emit();
  }

  callIsLinkButtonEnabled(): boolean {
    return this.isLinkButtonEnabled;
  }

  toggleSelected(tabKey: string | null) {
    this.selectedTabKey = tabKey;
  }

  isMobile() {
    return this.deviceService.isMobile();
  }

  isTablet() {
    return this.deviceService.isTablet();
  }

  isDesktop() {
    return this.deviceService.isDesktop();
  }
}
