import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { IButton } from "@bitwarden/web-vault/app/components/gloss-button/gloss-button.interface";
import { InstitutionsAddEditComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-institutions/institutions-add-edit/institutions-add-edit.component";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { Country } from "@bitwarden/web-vault/app/models/types/general-types";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";

@Component({
  selector: "app-institution-selection",
  templateUrl: "./institution-selection.component.html",
})
export class InstitutionSelectionComponent implements OnInit, OnDestroy {
  form: FormGroup;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  /** All institutions known to Gloss. TODO this will come from the gloss api after some point */
  institutions: Institution[] = [];
  /** The ones user have set/used for self so far*/
  userInstitutions: Institution[] = [];
  institutionAddModalRef: MatDialogRef<InstitutionsAddEditComponent>;
  selectedCountry: Country = null;
  previousButtonOptions: IButton = {
    text: "Previous",
    class: "neutral",
    onClick: this.previous.bind(this),
  };

  nextButtonOptions: IButton = {
    text: "Next",
    type: "submit",
    onClick: this.next.bind(this),
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      closeDialogue: CallableFunction;
      openInstitutionDialogue: CallableFunction;
      openCreationOptionsDialogue: CallableFunction;
      openAccountAddModal: CallableFunction;
    },
    public dialog: MatDialog,
    private dataRepositoryService: DataRepositoryService,
    private globalService: GlobalService,
    private formBuilder: FormBuilder,
    private institutionService: InstitutionService
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.institutions = await this.institutionService.getInstitutionsMasterListObservable();
      this.userInstitutions = await this.dataRepositoryService.getAllInstitutions();
    } catch (error) {
      this.institutions = [];
      this.userInstitutions = [];
    }
    this.initializeForm();
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      institution: [null, Validators.required],
      country: [null, Validators.required],
    });
  }

  previous() {
    this.data.closeDialogue();
    this.data.openCreationOptionsDialogue();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  setInstitution(institution: Institution) {
    this.form.get("institution")?.setValue(institution);
  }

  setCountry(country: Country) {
    if (country !== null) {
      this.selectedCountry = country;
      this.form.get("country")?.setValue(country);
    }
  }

  async institutionCreated() {
    this.userInstitutions = await this.dataRepositoryService.getAllInstitutions();
    const lastInstitution = this.userInstitutions[this.userInstitutions.length - 1];
    this.setInstitution(lastInstitution);
    this.institutionAddModalRef.close();
  }

  next() {
    const selectedInstitution = this.form.value.institution;
    if (this.form.valid) {
      this.data.closeDialogue();
      this.data.openAccountAddModal(selectedInstitution);
    } else {
      this.nextButtonOptions.enableButton(true);
      this.globalService.showWarningMessage("warning", "institutionSelectionInfo");
    }
  }

  createNewInstitution() {
    const dialogRef = this.dialog.open(InstitutionsAddEditComponent, {
      width: "800px", // for mat-dialog-container, built in max-width: 80vw; so this width works for smaller screens too
      data: {
        institution: null,
        actionSucceeded: this.institutionCreated.bind(this),
      },
      autoFocus: ".auto-focus",
      backdropClass: "custom-backdrop-class",
      disableClose: true,
    });

    this.institutionAddModalRef = dialogRef;

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }
}
