import { BasiqConfigType } from "../../../models/types/environement-config.type";

const basiqConfig = process.env.BASIQ as unknown as BasiqConfigType;

export class GlossApiProxyRequest {
  authed = true;
  data: any;
  hasResponse = true;
  method: "GET" | "POST" | "PUT" | "DELETE" = "POST";
  path = "";
  url = `${basiqConfig.url}/${basiqConfig.apiStage}`;
  alterHeaders: any = null;

  constructor(data: any) {
    this.data = data;
    this.setPath(data);
  }

  private setPath(data: any) {
    const pathParts = data.endpoint;

    if (pathParts.length && pathParts.includes("connections")) {
      this.path = basiqConfig.endpoint.connections;
      this.method = "GET";
      this.data = null;
    }

    if (pathParts.length && pathParts.includes("consents")) {
      this.path = basiqConfig.endpoint.consents;
      this.method = "GET";
      this.data = null;
    }

    if (pathParts === "institution") {
      this.path = `${basiqConfig.endpoint.institutions}/${data.institutionId}`;
      this.method = "GET";
      this.data = null;
    }

    if (pathParts.length && pathParts.includes("accounts")) {
      this.path = basiqConfig.endpoint.accounts;
      this.method = "GET";
      this.data = null;
    }

    if (pathParts.length && pathParts.includes("transactions")) {
      this.path = basiqConfig.endpoint.transactions;
    }

    if (pathParts.length && pathParts.includes("jobs")) {
      this.path = basiqConfig.endpoint.jobs;
    }

    if (pathParts.length && pathParts.includes("jobs") && data.jobId) {
      this.path = `${basiqConfig.endpoint.jobs}/${data.jobId}`;
      this.method = "GET";
      this.data = null;
    }

    if (pathParts.length && pathParts.includes("accountConnections")) {
      this.path = `${basiqConfig.endpoint.accounts}/${data.institutionId}/connections`;
      this.method = "GET";
      this.data = null;
    }

    if (pathParts === "connection") {
      this.path = `${basiqConfig.endpoint.connections}/${data.connectionId}`;
      this.method = "GET";
      this.data = null;
    }

    if (pathParts === "refreshConnection") {
      this.path = `${basiqConfig.endpoint.connections}/${data.connectionId}/refresh`;
      this.method = "PUT";
      this.data = null;
    }
  }
}
