import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, takeUntil } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { currencies } from "@bitwarden/web-vault/app/gloss/settings/manage-preferences/currencies";
import { dateFormats } from "@bitwarden/web-vault/app/gloss/settings/manage-preferences/dateformats";
import { timezones } from "@bitwarden/web-vault/app/gloss/settings/manage-preferences/timezones";
import { Preference } from "@bitwarden/web-vault/app/models/data/blobby/preference.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { PreferenceResponse } from "@bitwarden/web-vault/app/models/data/response/preference.response";
import {
  StringToNumberHelperOfMonth,
  StringToNumberHelperOfWeek,
  UserPreference,
} from "@bitwarden/web-vault/app/models/types/PrefereneceTypes";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { MarketDataService } from "@bitwarden/web-vault/app/services/DataService/market-data/market-data.service";
import { monthDays } from "@bitwarden/web-vault/app/services/DataService/preference/month-days";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { PreferenceState } from "@bitwarden/web-vault/app/services/DataService/preference/preference.state";
import {
  getPreferenceSetting,
  getError,
} from "@bitwarden/web-vault/app/services/DataService/preference/state/preference.reducer";
import { weekDays } from "@bitwarden/web-vault/app/services/DataService/preference/week-days";
import { yearMonths } from "@bitwarden/web-vault/app/services/DataService/preference/year-months";
import {
  StateManagement,
  StateManagementType,
  StoreState,
} from "@bitwarden/web-vault/app/services/DataService/state-management/state.management";
import { getTransactions } from "@bitwarden/web-vault/app/services/DataService/transaction/state/transaction.reducer";
import Actions from "@bitwarden/web-vault/app/state/app.actions";

import "./manage-preferences.scss";

@Component({
  selector: "app-manage-preferences",
  templateUrl: "./manage-preferences.component.html",
  styles: ["manage-preferences.scss"],
})
export class ManagePreferencesComponent extends StateManagement implements OnInit {
  @Output() toggleDisplay = new EventEmitter<void>();

  preferredCurrency = "";
  preferredTimeZone = "";
  preferredDateFormat = "";
  preferredMode = "";
  preferredVisualYearMonthStartName: string | number = "";
  preferredVisualWeekDayStartName: string | number = "";
  preferredMonthDayStart: string | number = "";
  preference: Preference;
  preferenceVisual: UserPreference;

  preferenceSetting$: Observable<Preference | boolean>;
  preferenceError$: Observable<unknown>;
  preferenceSetting: StateManagementType;

  transactions$: Observable<Transaction[]>;
  transactions: StateManagementType;

  //TODO 1 : currencies should come automatically from somewhere
  // TODO 2 : dateFormates sould also be automotic
  // TODO 3 modes should be some sort of data enum or type maybe
  loading: boolean;
  currencies: string[] = currencies;
  filteredCurrencies: string[] = currencies;
  zones: string[] = timezones;
  filteredZones: string[] = timezones;
  weekDaysArr: StringToNumberHelperOfWeek[]; // In order to make an iterable over the record items we have to turn them to arrays with getWeekDaysArray
  yearMonthsArr: StringToNumberHelperOfMonth[]; // In order to make an iterable over the record items we have to turn them to arrays with getWeekDaysArray
  preferenceExists = true;
  dFormats: string[] = dateFormats;
  filteredDateFormats: string[] = dateFormats;
  monthDays: number[] = monthDays;
  modes: string[] = ["Basic", "Experimental"];
  constructor(
    private store: Store<StoreState>,
    private preferenceService: PreferenceService,
    private preferenceState: PreferenceState,
    private globalServices: GlobalService,
    private dataRepositoryService: DataRepositoryService,
    private marketDataService: MarketDataService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.preferredCurrency = this.filteredCurrencies[0];
    /** NgRx */
    this.preferenceError$ = this.store.select(getError);
    this.preferenceSetting$ = this.store.select(getPreferenceSetting);
    this.transactions$ = this.store.select(getTransactions);

    this.loading = false;
    this.weekDaysArr = this.preferenceService.getWeekDaysArray(weekDays);
    this.yearMonthsArr = this.preferenceService.getYearMonthsArray(yearMonths);
    try {
      const vaultPreferences = await this.preferenceService.getAll();
      this.preferenceState
        .getState()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((preferenceState) => {
          if (preferenceState?.preferenceType && preferenceState?.preferenceVisual) {
            const observablePreference = new Preference(
              new PreferenceResponse(preferenceState.preferenceType)
            );
            if (vaultPreferences && vaultPreferences.id === observablePreference.id) {
              this.preference = new Preference(
                new PreferenceResponse(preferenceState.preferenceType)
              );
              this.preferenceVisual = preferenceState.preferenceVisual;
              this.preferenceSetting = this.preference;
              this.preferenceExists = true;

              this.handleGetPreferenceState();
              this.setExistingPrefValues();
            } else {
              this.preferenceState.getInitialState();
            }
          } else {
            this.preferenceExists = false;
          }
        });
    } catch (e) {
      this.globalServices.showErrorMessage("errorOccurred", e);
    }
  }

  async ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /** Assumes Preference and preferenceVisual is set and defined*/
  setExistingPrefValues() {
    if (this.preferenceSetting instanceof Preference) {
      this.preferredCurrency = this.preferenceSetting.baseCurrency;
      // this.preferredTimeZone = this.preferenceSetting.timeZone;
      this.preferredDateFormat = this.preferenceSetting.dateFormat;
      this.preferredMode = this.preferenceSetting.mode;
      // this.preferredVisualYearMonthStartName = this.getDateValue(
      //   this.preferenceSetting.YearMonthStart
      // );
      // this.preferredVisualWeekDayStartName = this.getDateValue(this.preferenceSetting.weekDayStart);
      // this.preferredMonthDayStart = this.preferenceSetting.monthDayStart;
    } else {
      this.preferredCurrency = this.preference.baseCurrency;
      // this.preferredTimeZone = this.preference.timeZone;
      this.preferredDateFormat = this.preference.dateFormat;
      this.preferredMode = this.preference.mode;
      // this.preferredVisualYearMonthStartName = this.preferenceVisual.YearMonthStartName;
      // this.preferredVisualWeekDayStartName = this.preferenceVisual.weekDayStartName;
      // this.preferredMonthDayStart = this.preferenceVisual.monthDayStart;
    }
  }

  cancelChanges() {
    this.setExistingPrefValues();
  }
  async delayFor(ms: number): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, ms);
    });
  }

  async submit(): Promise<void> {
    await this.delayFor(500);
    if (!this.isSafe()) {
      this.globalServices.showErrorMessage("errorOccurred", "emptyRequiredFields");
      return;
    }
    this.loading = true;
    this.handleGetTransactionState();

    /*    const weekStartDayArr = this.weekDaysArr.find(
      (w) => w.day === this.preferredVisualWeekDayStartName
    );
    const yearStartMontArr = this.yearMonthsArr.find(
      (w) => w.month === this.preferredVisualYearMonthStartName
    );*/

    const preferenceObj: any = {
      // weekDayStart: { [weekStartDayArr.day]: weekStartDayArr.value },
      // YearMonthStart: { [yearStartMontArr.month]: yearStartMontArr.value },
      // monthDayStart: this.preferredMonthDayStart,
      baseCurrency: this.preferredCurrency,
      // timeZone: this.preferredTimeZone,
      dateFormat: this.preferredDateFormat,
      mode: this.preferredMode,
    };

    const { PreferenceActions } = Actions;
    try {
      let addedPref;
      if (this.preferenceExists) {
        const updatedPreference = new Preference(
          new PreferenceResponse({ ...this.preference, ...preferenceObj })
        );
        addedPref = await this.preferenceService.update(updatedPreference);

        const prefVisual = await this.preferenceService.getUserPreferenceObject();

        this.store.dispatch(PreferenceActions.update({ updatedPreference }));
        this.preferenceState.setState({ preferenceType: addedPref, preferenceVisual: prefVisual });
        //  this.store.dispatch(RevaluationActions.create());
      } else {
        const preference = new Preference(new PreferenceResponse(preferenceObj));
        addedPref = await this.preferenceService.create(preference);

        const prefVisual = await this.preferenceService.getUserPreferenceObject();
        this.preferenceState.setState({ preferenceType: addedPref, preferenceVisual: prefVisual });
      }

      const transactions = await this.dataRepositoryService.getAllTransactions(false);
      await this.marketDataService.refreshMarketData(transactions);

      if (addedPref instanceof Preference) {
        this.preferenceSetting = addedPref;
        this.setExistingPrefValues();
        this.globalServices.showSuccessMessage("succeeded", "preferencesUpdated");
      }
    } catch (e) {
      this.globalServices.showErrorMessage("errorOccurred", "somethingWentWrong");
    }

    this.loading = false;
  }

  isSafe(): boolean {
    return !!this.preferredCurrency && !!this.preferredDateFormat;
    //  && !!this.preferredTimeZone
  }
  filter(filterable: string[], toFilterWith: string) {
    return filterable.filter((inArrayValue) =>
      inArrayValue.toLowerCase().includes(toFilterWith.toString().toLowerCase())
    );
  }

  searchForCurrency(): void {
    this.filteredCurrencies = this.filter(this.currencies, this.preferredCurrency);
  }

  async onBaseCurrencyBlur(): Promise<void> {
    await this.delayFor(200);

    const isEmpty = !this.preferredCurrency || this.preferredCurrency?.replace(/\s/g, "") === "";
    if (isEmpty) {
      this.resetCurrency();
      this.globalServices.showErrorMessage("errorOccurred", "emptyCurrency");
      return;
    }

    const isValid = this.currencies.includes(this.preferredCurrency);
    if (!isValid) {
      this.resetCurrency();
      this.globalServices.showErrorMessage("errorOccurred", "invalidCurrency");
    }
  }

  searchForTimeZones(): void {
    this.filteredZones = this.filter(this.zones, this.preferredTimeZone);
  }

  resetTimeZone(): void {
    this.preferredTimeZone = null;
    this.filteredZones = this.zones;
  }

  resetCurrency(): void {
    this.preferredCurrency = null;
    this.filteredCurrencies = this.currencies;
  }

  resetDateFormat(): void {
    this.preferredDateFormat = null;
    this.filteredDateFormats = this.dFormats;
  }

  async onTimeZonesBlur(): Promise<void> {
    await this.delayFor(200);

    const isEmpty = !this.preferredTimeZone || this.preferredTimeZone?.replace(/\s/g, "") === "";
    if (isEmpty) {
      this.resetTimeZone();
      this.globalServices.showErrorMessage("errorOccurred", "emptyTimeZone");
      return;
    }

    const isValid = this.zones.includes(this.preferredTimeZone);
    if (!isValid) {
      this.resetTimeZone();
      this.globalServices.showErrorMessage("errorOccurred", "invalidTimeZone");
    }
  }

  searchForDateFormat(): void {
    this.filteredDateFormats = this.filter(this.dFormats, this.preferredDateFormat);
  }

  async onDateFormatBlur(): Promise<void> {
    await this.delayFor(200);

    const isEmpty =
      !this.preferredDateFormat || this.preferredDateFormat?.replace(/\s/g, "") === "";
    if (isEmpty) {
      this.resetDateFormat();
      this.globalServices.showErrorMessage("errorOccurred", "emptyDateFormat");
      return;
    }

    const isValid = this.dFormats.includes(this.preferredDateFormat);
    if (!isValid) {
      this.resetDateFormat();
      this.globalServices.showErrorMessage("errorOccurred", "invalidDateFormat");
    }
  }

  handleGetTransactionState() {
    this.transactions = this.getState(this.transactions$);
  }

  handleGetPreferenceState() {
    this.preferenceSetting = this.getState(this.preferenceSetting$);
  }

  /** Temporary flag to hide certain fields */
  hideField(field: string) {
    const hiddenFields = ["timezone", "dateStart"];

    return hiddenFields.includes(field);
  }

  private getDateValue(preferenceSetting: unknown) {
    return Object.keys(preferenceSetting)[0];
  }

  redirectToSettings() {
    this.toggleDisplay.emit();
  }
}
