<div
  class="modal tw-fixed tw-inset-0 tw-z-50 tw-flex tw-overflow-y-auto tw-bg-neutral-800 tw-bg-opacity-60"
  role="dialog"
  aria-modal="true"
  aria-labelledby="purgeVaultTitle"
>
  <div class="tw-relative tw-mx-auto tw-my-6 tw-w-full tw-max-w-xl" role="document">
    <form
      class="tw-flex tw-flex-col tw-gap-6 tw-rounded-lg tw-bg-white tw-p-6"
      #form
      (ngSubmit)="submit()"
      [appApiAction]="formPromise"
      ngNativeValidate
    >
      <div class="tw-flex md:tw-justify-between">
        <h1 class="modal-title" id="purgeVaultTitle">{{ "purgeVault" | i18n }}</h1>
        <button
          type="button"
          class="close tw-hidden md:tw-flex"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-6">
        <p class="tw-mb-0 tw-text-justify">
          {{ (organizationId ? "purgeOrgVaultDesc" : "purgeVaultDesc") | i18n }}
        </p>

        <!--        todo: refactor app-callout-->
        <!--        <app-callout type="warning">{{ "purgeVaultWarning" | i18n }}</app-callout>-->

        <div
          class="tw-flex tw-h-auto tw-w-full tw-min-w-[350px] tw-flex-col tw-gap-2 tw-rounded-lg tw-border-[2px] tw-border-solid tw-border-danger tw-py-2 tw-px-4 md:tw-w-full md:tw-min-w-[300px]"
        >
          <div class="tw-flex tw-items-center tw-gap-2">
            <h6 class="tw-mb-0">
              <svg class="tw-h-5 tw-w-5">
                <use xlink:href="images/sprite.svg#info-danger" />
              </svg>
            </h6>
            <div class="">
              <h6 class="tw-mb-0 tw-whitespace-nowrap tw-font-semibold tw-text-danger">
                {{ "warning" | i18n }}
              </h6>
            </div>
          </div>
          <div>
            <p class="tw-mb-0 tw-text-justify">{{ "purgeVaultWarning" | i18n }}</p>
          </div>
        </div>

        <app-user-verification [(ngModel)]="masterPassword" ngDefaultControl name="secret">
        </app-user-verification>
      </div>
      <div class="tw-flex tw-justify-around tw-gap-6">
        <button
          type="submit"
          class="btn btn-danger btn-submit tw-h-12 tw-w-full tw-rounded-full tw-shadow-button"
          [disabled]="form.loading"
        >
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ "purgeVault" | i18n }}</span>
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary tw-h-12 tw-w-full tw-rounded-full tw-shadow-button md:tw-hidden"
          data-dismiss="modal"
        >
          {{ "close" | i18n }}
        </button>
      </div>
    </form>
  </div>
</div>
