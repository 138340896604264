import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { BaseProgressBar } from "../../components/progress-bar/base-progress-bar";
import { IMainProgressBar } from "../../models/interfaces/main-progress-bar";

@Injectable({
  providedIn: "root",
})
export class MainProgressBar extends BaseProgressBar implements IMainProgressBar {
  private readonly DEFAULT_PROGRESS_LABEL = "Loading...";
  private loadingPercentage$ = new BehaviorSubject<number>(0);
  private isLoadingCompleted$ = new BehaviorSubject<boolean>(false);
  private progressLabel$ = new BehaviorSubject<string>(this.DEFAULT_PROGRESS_LABEL);

  setLoadingPercentage(percentage: number) {
    this.loadingPercentage$.next(percentage);
  }

  getLoadingPercentage() {
    return this.loadingPercentage$;
  }

  setIsLoadingCompleted(isLoadingCompleted: boolean) {
    this.isLoadingCompleted$.next(isLoadingCompleted);
  }

  getIsLoadingCompleted() {
    return this.isLoadingCompleted$;
  }

  setProgressLabel(label: string) {
    this.progressLabel$.next(label);
  }

  getProgressLabel() {
    return this.progressLabel$;
  }

  reset() {
    this.setIsLoadingCompleted(false);
    this.setProgressLabel(this.DEFAULT_PROGRESS_LABEL);
    this.setLoadingPercentage(0);
  }
}
