<app-spinner [showSpinner]="loading"></app-spinner>
<ng-container>
  <div class="tw-w-[980px] tw-min-w-[360px] lg:tw-w-75vw">
    <app-progress-bar *ngIf="isImporting" [isStart]="isImporting"></app-progress-bar>
    <div class="tw-flex tw-flex-col">
      <div
        class="tw-mb-[20px] tw-flex tw-h-[150px] tw-items-center tw-justify-center tw-rounded-lg tw-bg-white tw-shadow-multi lg:tw-h-20 lg:tw-flex-col"
      >
        <div
          class="tw-relative tw-flex tw-w-[795px] tw-items-center tw-justify-center lg:tw-hidden"
        >
          <div class="tw-absolute tw-top-[-20px] tw-z-[2] tw-flex tw-w-full tw-justify-between">
            <div class="step-check-icon-section">
              <svg class="tw-h-12 tw-w-12">
                <use [attr.xlink:href]="'images/sprite.svg#' + stepAfterComplete(1)" />
              </svg>
              <p>{{ "addAccount" | i18n }}</p>
            </div>
            <div class="step-check-icon-section">
              <svg class="tw-h-12 tw-w-12">
                <use [attr.xlink:href]="'images/sprite.svg#' + stepAfterComplete(2)" />
              </svg>
              <p>{{ setBalanceProgressTitle | i18n }}</p>
            </div>
            <div class="step-check-icon-section">
              <svg class="tw-h-12 tw-w-12">
                <use [attr.xlink:href]="'images/sprite.svg#' + stepAfterComplete(3)" />
              </svg>
              <p class="tw--mr-[15px]">{{ "viewYourForecast" | i18n }}</p>
            </div>
          </div>
          <div class="tw-flex tw-w-[700px] tw-items-center tw-justify-center">
            <div class="progress-bar-section">
              <div [ngClass]="addProgressBarClass()"></div>
              <img
                class="tw-block tw-w-full tw-grayscale tw-filter"
                src="images/icons/wizard/progress-bar.png"
                alt="progress-bar"
              />
            </div>
          </div>
        </div>
        <div
          class="tw-relative tw-mt-6 tw-hidden tw-w-[75%] tw-items-center tw-justify-center lg:tw-flex"
        >
          <div class="tw-absolute tw-flex tw-w-full tw-items-center">
            <div class="tw-flex-col tw-items-center tw-gap-4">
              <div
                class="tw-flex tw-h-5 tw-w-5 tw-items-start tw-justify-center tw-rounded-full tw-shadow-inner"
                [ngClass]="{
                  'tw-border-2 tw-border-solid tw-border-primary tw-bg-white': !isButtonEnabled(1),
                  'tw-bg-primary': isButtonEnabled(1)
                }"
              ></div>
            </div>
            <div
              class="tw-flex tw-h-1 tw-w-[50%] tw-items-center tw-justify-center tw-shadow-inner"
              [ngClass]="{
                'tw-bg-primary': !isButtonEnabled(2) || !isButtonEnabled(3),
                'tw-bg-neutral-100': !isButtonEnabled(1)
              }"
            ></div>
            <div class="tw-flex-col tw-items-center tw-gap-4">
              <div
                class="tw-flex tw-h-5 tw-w-5 tw-items-start tw-justify-center tw-rounded-full tw-shadow-inner"
                [ngClass]="{
                  'tw-border-2 tw-border-solid tw-border-primary tw-bg-white':
                    !isButtonEnabled(2) && isButtonEnabled(3),
                  'tw-bg-primary': !isButtonEnabled(3),
                  'tw-bg-neutral-100': !isButtonEnabled(1)
                }"
              ></div>
            </div>
            <div
              class="tw-flex tw-h-1 tw-w-[50%] tw-items-center tw-justify-center tw-shadow-inner"
              [ngClass]="{
                'tw-bg-primary': !isButtonEnabled(3),
                'tw-bg-neutral-100': isButtonEnabled(3)
              }"
            ></div>
            <div class="tw-flex-col tw-items-center tw-gap-4">
              <div
                class="tw-flex tw-h-5 tw-w-5 tw-items-start tw-justify-center tw-rounded-full tw-shadow-inner"
                [ngClass]="{
                  'tw-border-2 tw-border-solid tw-border-primary tw-bg-white': !isButtonEnabled(3),
                  'tw-bg-neutral-100': isButtonEnabled(3)
                }"
              ></div>
            </div>
          </div>
        </div>

        <div class="tw-mt-4 tw-hidden lg:tw-flex">
          <span *ngIf="!isButtonEnabled(1)">{{ "addAccount" | i18n }}</span>
          <span *ngIf="!isButtonEnabled(2)">{{ setBalanceProgressTitle | i18n }}</span>
          <span *ngIf="!isButtonEnabled(3)">{{ "viewForecast" | i18n }}</span>
        </div>
      </div>

      <div class="tw-flex lg:tw-w-full lg:tw-justify-center">
        <div class="step-card lg:tw-w-full" [ngClass]="{ 'lg:tw-hidden': isButtonEnabled(1) }">
          <div class="card-content tw-h-[85%] lg:tw-h-[80%] lg:tw-gap-2">
            <h2>{{ "startAddingAccountTitle" | i18n }}</h2>
            <p>
              {{ "startAddingAccountDescription" | i18n }}
            </p>
          </div>

          <app-gloss-button
            [options]="{
              text: addAccountButton | i18n,
              onClick: openCreationOptionsModal.bind(this),
              isEnabled: !isButtonEnabled(1)
            }"
          >
          </app-gloss-button>
        </div>
        <div class="tw-w-5p tw-flex tw-items-center lg:tw-hidden">
          <img src="images/icons/wizard/vector.svg" class="tw-mx-1" alt="vector" />
        </div>

        <div class="step-card lg:tw-w-full" [ngClass]="{ 'lg:tw-hidden': isButtonEnabled(2) }">
          <div class="card-content tw-h-[85%] lg:tw-h-[80%] lg:tw-gap-2">
            <h2>{{ setBalanceTitle | i18n }}</h2>
            <div>
              {{ setBalanceDescription | i18n }}
            </div>
          </div>
          <app-gloss-button
            [options]="{
              text: setBalanceButton | i18n,
              onClick: addBalanceSelected.bind(this),
              isEnabled: !isButtonEnabled(2)
            }"
          >
          </app-gloss-button>
        </div>
        <div class="tw-w-5p tw-flex tw-items-center lg:tw-hidden">
          <img src="../../../images/icons/wizard/vector.svg" class="tw-mx-1" alt="vector" />
        </div>

        <div class="step-card lg:tw-w-full" [ngClass]="{ 'lg:tw-hidden': isButtonEnabled(3) }">
          <div class="card-content tw-h-[85%] lg:tw-h-[80%] lg:tw-gap-2">
            <h2>{{ "viewForecastTitle" | i18n }}</h2>
            <p>
              {{ "viewForecastDescription" | i18n }}
            </p>
          </div>
          <app-gloss-button
            [options]="{
              text: viewForecastButton | i18n,
              onClick: wizardExit.bind(this),
              isEnabled: !isButtonEnabled(3)
            }"
          >
          </app-gloss-button>
        </div>
      </div>
    </div>
  </div>
  <app-spinner [showSpinner]="spinnerLoader"></app-spinner>
</ng-container>
