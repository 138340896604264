import { Component, EventEmitter, Output } from "@angular/core";

import { ModalService } from "@bitwarden/angular/services/modal.service";

import { RoleScope } from "../models/enum/role-access.enum";

import { DeauthorizeSessionsComponent } from "./deauthorize-sessions.component";
import { DeleteAccountComponent } from "./delete-account.component";
import { PurgeVaultComponent } from "./purge-vault.component";

@Component({
  selector: "app-danger-zone",
  templateUrl: "./danger-zone.component.html",
})
export class DangerZoneComponent {
  @Output() toggleDisplay = new EventEmitter<void>();

  constructor(private modalService: ModalService) {}

  async deauthorizeSessions() {
    this.modalService.open(DeauthorizeSessionsComponent, { allowMultipleModals: true });
  }

  async purgeVault() {
    this.modalService.open(PurgeVaultComponent, { allowMultipleModals: true });
  }

  async deleteAccount() {
    this.modalService.open(DeleteAccountComponent, { allowMultipleModals: true });
  }

  redirectToSettings() {
    this.toggleDisplay.emit();
  }

  protected readonly RoleScope = RoleScope;
}
