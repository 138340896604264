import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppLoadService {
  private isAppLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private isAppLoading: any;

  getIsAppLoading(): boolean {
    return this.isAppLoading;
  }

  setIsAppLoading(value: boolean): void {
    this.isAppLoading = value;
  }

  trackAppLoading(value: boolean): void {
    this.isAppLoadingSubject.next(value);
  }

  setTrackAppLoading(): void {
    this.isAppLoadingSubject.subscribe((value) => {
      this.setIsAppLoading(value);
    });
  }
}
