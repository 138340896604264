<ng-container>
  <div
    class="app-bar tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-w-full tw-justify-around tw-bg-white"
  >
    <ng-container *ngFor="let item of menuItems">
      <nav
        class="tw-flex tw-h-full tw-min-w-[25%] tw-flex-col tw-items-center tw-justify-center"
        [routerLink]="item.routerLink"
        (click)="selectLink(item.key)"
        [ngClass]="{ active: selectedKey === item.key }"
      >
        <svg class="tw-h-7 tw-w-7" [ngClass]="{ 'tw-stroke-primary': selectedKey !== item.key }">
          <use
            [attr.xlink:href]="
              'images/sprite.svg#' + (selectedKey === item.key ? item.selectedIcon : item.icon)
            "
          />
        </svg>
        <span class="tw-font-medium">{{ item.label | i18n }}</span>
      </nav>
    </ng-container>
  </div>
</ng-container>
