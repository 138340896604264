<div
  class="tw-flex tw-flex-col tw-gap-6 tw-rounded-lg tw-bg-white tw-p-6 md:tw-h-full md:tw-w-full md:tw-p-4"
>
  <nav
    class="tw-relative tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-gap-4"
    (click)="redirectToSettings()"
  >
    <div class="md:tw-hidden">
      <svg class="tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#left-arrow" />
      </svg>
    </div>
    <div
      class="tw-hidden tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-bg-neutral-50 tw-shadow-button md:tw-flex"
    >
      <svg class="tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#left-arrow" />
      </svg>
    </div>
    <span class="tw-text-xl tw-font-semibold tw-text-neutral-800 md:tw-hidden">{{
      "changeMasterPassword" | i18n
    }}</span>
    <div class="tw-absolute tw-hidden tw-w-full tw-items-center tw-justify-center md:tw-flex">
      <span class="tw-text-xl tw-font-semibold tw-text-neutral-800">{{
        "changeMasterPassword" | i18n
      }}</span>
    </div>
  </nav>

  <app-callout
    type="info"
    [enforcedPolicyOptions]="enforcedPolicyOptions"
    *ngIf="enforcedPolicyOptions"
  >
  </app-callout>
  <div
    class="tw-flex tw-h-auto tw-w-[80%] tw-min-w-[350px] tw-flex-col tw-rounded-lg tw-border-[2px] tw-border-solid tw-border-danger tw-py-6 tw-px-6 md:tw-w-full md:tw-min-w-[300px] md:tw-py-4"
  >
    <div class="tw-flex tw-gap-2">
      <h6>
        <svg class="tw-h-5 tw-w-5">
          <use xlink:href="images/sprite.svg#info-danger" />
        </svg>
      </h6>
      <div class="tw-flex tw-flex-col">
        <h6 class="tw-whitespace-nowrap tw-font-semibold tw-text-danger">
          {{ "dangerZone" | i18n }}
        </h6>
        <div>
          <p class="tw-mb-0">{{ "loggedOutWarning" | i18n }}</p>
        </div>
      </div>
    </div>
  </div>

  <form
    #form
    (ngSubmit)="submit()"
    [appApiAction]="formPromise"
    ngNativeValidate
    autocomplete="off"
    class="tw-flex tw-w-fit tw-min-w-[400px] tw-max-w-[500px] tw-flex-col tw-gap-6 xs:tw-items-center xs:tw-justify-center md:tw-w-full md:tw-min-w-[300px]"
  >
    <div class="tw-w-full">
      <label for="currentMasterPassword">{{ "currentMasterPass" | i18n }}</label>
      <input
        id="currentMasterPassword"
        type="password"
        name="MasterPasswordHash"
        class="form-control"
        [(ngModel)]="currentMasterPassword"
        required
        appInputVerbatim
      />
    </div>
    <div class="tw-flex tw-w-full tw-gap-6 xs:tw-flex-col">
      <div class="tw-flex tw-w-[50%] xs:tw-w-full">
        <div class="tw-w-full">
          <label for="newMasterPassword">{{ "newMasterPass" | i18n }}</label>
          <input
            id="newMasterPassword"
            type="password"
            name="NewMasterPasswordHash"
            class="form-control mb-1"
            [(ngModel)]="masterPassword"
            required
            appInputVerbatim
            autocomplete="new-password"
          />
          <app-password-strength
            [password]="masterPassword"
            [email]="email"
            [showText]="true"
            (passwordStrengthResult)="getStrengthResult($event)"
          >
          </app-password-strength>
        </div>
      </div>

      <div class="tw-flex tw-w-[50%] xs:tw-w-full">
        <div class="tw-w-full">
          <label for="masterPasswordRetype" class="tw-whitespace-nowrap">{{
            "confirmNewMasterPass" | i18n
          }}</label>
          <input
            id="masterPasswordRetype"
            type="password"
            name="MasterPasswordRetype"
            class="form-control"
            [(ngModel)]="masterPasswordRetype"
            required
            appInputVerbatim
            autocomplete="new-password"
          />
        </div>
      </div>
    </div>

    <!--    todo: password hint design is a bit different https://www.figma.com/design/cCdcAGMB3MR1Sd6l3L8SvS/Gloss-%7C-User-Access-page?node-id=105-14279&node-type=frame&t=wHY8v8w8GxQzMvhr-0-->
    <div class="tw-w-full">
      <label for="masterPasswordHint">{{ "masterPassHintLabel" | i18n }}</label>
      <input
        id="masterPasswordHint"
        class="form-control"
        maxlength="50"
        type="text"
        name="MasterPasswordHint"
        [(ngModel)]="masterPasswordHint"
      />
    </div>

    <button
      type="submit"
      class="btn btn-primary btn-submit tw-h-10 tw-w-32 tw-rounded-full !tw-text-neutral-800 tw-shadow-button hover:!tw-text-white xs:tw-w-full"
      [disabled]="form.loading"
    >
      <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      <span>{{ "save" | i18n }}</span>
    </button>
  </form>
</div>
