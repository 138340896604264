import { Component, DoCheck, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { StepModel } from "@bitwarden/web-vault/app/components/account-wizard-stepper/step.model";
import { StepsService } from "@bitwarden/web-vault/app/components/account-wizard-stepper/wizard-stepper-service";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { SideMenuService } from "@bitwarden/web-vault/app/services/menu/side-menu.service";
import {
  DeviceDetection,
  DeviceDetector,
} from "@bitwarden/web-vault/app/shared/utils/device-detector";

@Component({
  selector: "app-user-layout",
  templateUrl: "user-layout.component.html",
})
export class UserLayoutComponent implements OnInit, DoCheck, OnDestroy {
  isGlossMenuOpen$ = this.glossMenuService.isGlossMenuOpen$;
  @ViewChild("glossMenu") glossMenu: MatSidenav;
  private ngUnsubscribe = new Subject<void>();
  deviceDetector: DeviceDetection;
  helpOpen = false;
  steps: StepModel[] = [];
  hasNoAccounts = false;
  hasNoTransaction = false;
  isWizardInitialise = false;

  constructor(
    private glossMenuService: SideMenuService,
    private blobbyService: BlobbyService,
    private router: Router,
    private stepsService: StepsService
  ) {
    this.deviceDetector = new DeviceDetector();
  }
  async ngOnInit() {
    this.handleWizardStep();
    document.body.classList.remove("layout_frontend");

    this.glossMenuService.shouldRunWizard$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ shouldRunWizard, resetBalance }) => {
        if (shouldRunWizard && !resetBalance) {
          this.router.navigate(["/dashboard"]).then(() => {
            this.isWizardInitialise = false;
            this.stepsService.restartWizardInProgress();
            this.handleWizardStep();
          });
        } else if (shouldRunWizard && resetBalance) {
          this.isWizardInitialise = true;
          this.handleWizardStep();
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngDoCheck() {
    if (this.blobbyService.getInitialised() && !this.isWizardInitialise) {
      const itemCountInstance = this.blobbyService.getItemCountService().getItemCountInstance();
      this.hasNoTransaction = itemCountInstance.getTotalTransaction() === 0;
      this.hasNoAccounts = itemCountInstance.getTotalAccount() === 0;

      if (this.hasNoAccounts) {
        localStorage.removeItem("wizardStepper");
      }
      this.isWizardInitialise = true;
      this.openWizard();
    }
  }

  handleHelpBoxOpen($event: boolean) {
    this.helpOpen = $event;
  }

  handleWizardStep() {
    this.stepsService.getSteps().subscribe({
      next: (steps) => {
        this.steps = steps;
      },
    });
  }

  openWizard() {
    const wizardMemory = localStorage.getItem("wizardStepper");
    if (this.hasNoAccounts || this.hasNoTransaction || wizardMemory) {
      if (
        this.steps[0].isComplete === false ||
        this.steps[1].isComplete === false ||
        this.steps[2].isComplete === false
      ) {
        this.stepsService.setIsWizardInProgress(true);
        return true;
      }
    }
    return false;
  }
}
