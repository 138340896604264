<button
  class="tw-h-14 tw-w-full tw-rounded-full tw-border-none tw-text-lg
  {{ isEnabled && 'btn-' + className }}
  {{ !isClicked ? 'tw-shadow-button' : 'tw-shadow-clicked' }}
  {{ 'tw-bg-neutral-50' && !isEnabled }}"
  [disabled]="!isEnabled"
  (click)="onButtonClicked()"
  tabindex="-1"
>
  <span class="tw-flex tw-justify-center">
    {{ options.text }}
    <svg *ngIf="options.icon" class="tw-ml-2 tw-h-6 tw-w-6">
      <use [attr.xlink:href]="'images/sprite.svg#' + options.icon"></use>
    </svg>
  </span>
</button>
