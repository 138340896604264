<div *ngIf="loading">
  <i
    class="bwi bwi-spinner bwi-spin text-muted"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="sr-only">{{ "loading" | i18n }}</span>
</div>

<form
  *ngIf="profile && !loading"
  #form
  (ngSubmit)="submit()"
  [appApiAction]="formPromise"
  ngNativeValidate
  class="tw-flex tw-w-fit tw-min-w-[270px] tw-max-w-[400px] tw-flex-col tw-gap-6 tw-bg-white md:tw-h-full md:tw-w-full md:tw-max-w-full md:tw-items-center md:tw-justify-between"
>
  <div class="tw-mt-6 tw-w-full">
    <div class="tw-flex tw-w-full tw-flex-col tw-gap-6 md:tw-justify-center">
      <!--      avatar-->
      <div class="md:tw-flex md:tw-w-full md:tw-items-center md:tw-justify-center">
        <bit-avatar [text]="profile | userName" [id]="profile.id" size="large"></bit-avatar>
      </div>
      <!--      name-->
      <div class="tw-flex tw-flex-col">
        <label for="name" class="tw-pointer-events-none">{{ "name" | i18n }}</label>
        <input
          class="tw-h-10 tw-rounded-lg tw-border-0 tw-border-neutral-100 tw-bg-neutral-50 tw-pl-6 focus:tw-border-[1px] focus:tw-border-solid focus:tw-border-primary focus:tw-shadow-inner focus:tw-outline-none"
          id="name"
          type="text"
          name="Name"
          [(ngModel)]="profile.name"
        />
      </div>
      <!--      email-->
      <div class="tw-flex tw-flex-col">
        <label for="email" class="tw-pointer-events-none">{{ "email" | i18n }}</label>
        <input
          id="email"
          class="tw-h-10 tw-rounded-lg tw-border-[1px] tw-border-solid tw-border-neutral-100 tw-bg-neutral-50 tw-pl-6 read-only:!tw-border-0 read-only:!tw-border-neutral-100 read-only:!tw-bg-neutral-50 focus:tw-border-[1px] focus:tw-border-solid focus:tw-border-primary focus:tw-shadow-inner focus:tw-outline-none"
          type="text"
          name="Email"
          [(ngModel)]="profile.email"
          readonly
        />
      </div>
    </div>
  </div>
  <!--  submit-->
  <!--  todo: the !important on text color is because the button behaviours are controlled by the theme, when we implement the theme we can remove it-->
  <button
    type="submit"
    class="btn btn-primary btn-submit tw-h-10 tw-w-32 tw-rounded-full !tw-text-neutral-800 tw-shadow-button hover:!tw-text-white xs:tw-w-full"
    [disabled]="form.loading"
  >
    <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
    <span>{{ "save" | i18n }}</span>
  </button>
</form>
