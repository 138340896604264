import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { subDays } from "date-fns";
import { BehaviorSubject, Observable } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { BaseImporter } from "@bitwarden/common/importers/base-importer";
import { ImportTransactionResult } from "@bitwarden/common/models/domain/import-transaction-result";
import { BasiqIoTransaction } from "@bitwarden/web-vault/app/importers/data-mapper/mappers/basiq-io/basiq-io-transaction";
import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { InstitutionResponse } from "@bitwarden/web-vault/app/models/data/response/institution.response";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { GlossNumber } from "@bitwarden/web-vault/app/models/data/shared/gloss-number";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { BasiqIoTransactionType } from "@bitwarden/web-vault/app/models/types/basiq-io-transaction.types";
import {
  BasiqAccountType,
  BasiqInstitution,
  BasiqJob,
  GlossConnectionResponse,
  GlossConnectionsResponse,
} from "@bitwarden/web-vault/app/models/types/basiq.types";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { BasiqTransactionProcessingService } from "@bitwarden/web-vault/app/services/api/basiq/transaction.processing.service";
import { BasiqIoConfigService } from "@bitwarden/web-vault/app/services/api/basiqio-config.service";
import { BasiqAccountService } from "@bitwarden/web-vault/app/shared/utils/helper.basiq/accounts";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";
import { getOpeningAlignmentTransaction } from "@bitwarden/web-vault/app/shared/utils/helper.transactions/alignment-transactions";
import { validationResult } from "@bitwarden/web-vault/app/validators/base-validator";

import { TransactionImporter } from "./transaction-importer";

export type BasiqImportState = {
  started: boolean;
  ended: boolean;
  success: boolean;
};

@Injectable({
  providedIn: "root",
})
export class TransactionBasiqImporter extends BaseImporter implements TransactionImporter {
  accountsMap: Map<string, Book> = new Map();
  private readonly BASIQ_TIMEZONE = "Australia/Sydney";
  private sortedExistingTransactions: Transaction[] = [];
  protected _basiqImportState = new BehaviorSubject<BasiqImportState>({
    started: false,
    ended: false,
    success: false,
  });
  basiqImportState$: Observable<BasiqImportState> = this._basiqImportState.asObservable();
  account: Book;
  basiqAccount: BasiqAccountType;
  openingBalance: GlossNumber;
  closingBalance: GlossNumber;
  totalAmount: GlossNumber;
  pendingAmount: GlossNumber;
  openingBalanceDate: string;
  hasBalance: boolean;
  private basiqAccounts: any;
  private records: validationResult;
  constructor(
    private dataRepositoryService: DataRepositoryService,
    private transactionProcessingService: BasiqTransactionProcessingService,
    private log: LogService,
    private basiqConfigService: BasiqIoConfigService,
    private basiqAccountService: BasiqAccountService,
    private basiqAuth: BasiqAuth,
    private route: ActivatedRoute,
    private router: Router,
    private dateFormat: DateFormat,
    private institutionService: InstitutionService
  ) {
    super();
  }

  startBasiqImport() {
    this._basiqImportState.next({ started: true, ended: false, success: null });
  }
  endFailureBasiqImport() {
    this._basiqImportState.next({ started: true, ended: true, success: false });
  }

  endSuccessBasiqImport() {
    this._basiqImportState.next({ started: true, ended: true, success: true });
  }

  completeBasiqImport() {
    this._basiqImportState.complete();
  }

  isBalancedTransactions(transactions: BasiqIoTransactionType[]) {
    const postedTransactions = transactions.filter(
      (transaction) => transaction.status === "posted"
    );
    if (postedTransactions.length === 0) {
      this.hasBalance = false;
    }

    if (postedTransactions[0].balance === "") {
      this.hasBalance = false;
    }

    let isBalanced = false;
    const count = postedTransactions.length > 20 ? 20 : postedTransactions.length;
    /** if first 20 transactions' balances are 0 this means transactions are without balance*/
    for (let i = 0; i < count; i++) {
      if (Number(postedTransactions[i].balance) !== 0) {
        isBalanced = true;
        break;
      }
    }

    this.hasBalance = isBalanced;
  }

  addAlignmentTransactions(validatedTransactions: validationResult) {
    if (this.openingBalance.amount !== 0) {
      this.addOpeningBalanceAlignment(this.account, validatedTransactions);
    }
  }

  addOpeningBalanceAlignment(account: Book, validatedTransactions: validationResult) {
    const { openingBalance, openingBalanceDate } = this;
    validatedTransactions.newRecord.unshift(
      getOpeningAlignmentTransaction(account, openingBalance.amount, openingBalanceDate)
    );
  }

  async parse(
    transactions: BasiqIoTransactionType[],
    isAddAlignment = true
  ): Promise<ImportTransactionResult> {
    this.isBalancedTransactions(transactions);
    await this.setAccountsMap();
    return await this.mapBasiqTransactions(transactions, isAddAlignment);
  }

  async setAccountsMap() {
    const accounts = await this.dataRepositoryService.getAllBooks();
    accounts.forEach((account) => {
      this.accountsMap.set(account.id, account);
    });
  }

  resetImporter(basiqTransaction: BasiqIoTransactionType) {
    this.account = this.accountsMap.get(basiqTransaction.account);
    this.openingBalance = new GlossNumber();
    this.closingBalance = new GlossNumber();
    this.totalAmount = new GlossNumber();
    this.pendingAmount = new GlossNumber();
    this.openingBalanceDate = "";
  }

  getOpeningBalanceDate(basiqTransactions: BasiqIoTransactionType[]): string {
    const earliestTransaction = basiqTransactions[basiqTransactions.length - 1];
    const latestTransaction = basiqTransactions[0];
    this.log.info(`Earliest transaction: ${JSON.stringify(earliestTransaction)}`);
    this.log.info(`Latest transaction: ${JSON.stringify(latestTransaction)}`);
    const dateOfFirstTransaction = earliestTransaction.transactionDate
      ? earliestTransaction.transactionDate.split("T")[0]
      : earliestTransaction.postDate.split("T")[0];
    const previousDate = subDays(new Date(dateOfFirstTransaction), 1);
    return previousDate.toISOString().split("T")[0];
  }

  async mapBasiqTransactions(
    basiqTransactions: BasiqIoTransactionType[],
    isAddAlignment = true
  ): Promise<ImportTransactionResult> {
    this.log.info(`Number of basiq transactions: ${basiqTransactions.length}`);
    const importTransactionResult = new ImportTransactionResult();
    const fakeTransactions: any = [];
    this.resetImporter(basiqTransactions[0]);
    importTransactionResult.transactions = basiqTransactions.map((basiqTransaction) => {
      fakeTransactions.push({
        date: basiqTransaction.transactionDate
          ? basiqTransaction.transactionDate
          : basiqTransaction.postDate,
        amount: basiqTransaction.amount,
      });
      basiqTransaction.balance = "";
      const transactionAmount = new GlossNumber().setToGlossNumberObj({
        amount: Number(basiqTransaction.amount),
      });
      this.totalAmount.add(transactionAmount);
      if (basiqTransaction.status === "pending") {
        this.pendingAmount.add(transactionAmount);
      }
      return this.basiqBasedTransaction(basiqTransaction);
    });

    this.log.info(`fake transactions: ${JSON.stringify(fakeTransactions)}`);
    if (isAddAlignment) {
      await this.updateAccountBalance();
    }
    this.openingBalanceDate = this.getOpeningBalanceDate(basiqTransactions);

    return importTransactionResult;
  }

  private calculateBalanceTruth() {
    this.log.info(`Pending amount : ${this.pendingAmount.amount}`);
    this.log.info(`Total amount before calc: ${this.totalAmount.amount}`);

    this.basiqAccount = this.basiqAccounts.data.find(
      (basiqAccount: BasiqAccountType) => basiqAccount.id === this.account.id
    );
    const accountBalance = new GlossNumber().setToGlossNumberObj({ amount: this.account.balance });
    this.log.info(`Account balance: ${accountBalance.amount}`);
    accountBalance.subtract(this.totalAmount);
    this.log.info(`this.totalAmount: ${this.totalAmount.amount}`);
    this.openingBalance = accountBalance;
    this.log.info(`this.openingBalance: ${this.openingBalance.amount}`);
    const basiqAvailableFunds = new GlossNumber().setToGlossNumberObj({
      amount: this.basiqAccount.availableFunds,
    });
    this.log.info(`Basiq available funds: ${basiqAvailableFunds.amount}`);
  }

  async updateAccountBalance() {
    this.calculateBalanceTruth();
  }

  basiqBasedTransaction(basiqTransaction: BasiqIoTransactionType) {
    /*TODO classifications and categories should be set on the basiqTransaction when they are being created in the flow */
    const {
      description,
      amount,
      account,
      balance,
      direction,
      transactionDate,
      postDate,
      classifications,
      categories,
      enrich,
    } = basiqTransaction;
    const glossAccount = this.accountsMap.get(account);
    const directionAndAmount = this.directionAndQuantity(basiqTransaction);
    //TODO need to make the Date concrete implementation (transactionDate and postDate is not equal)
    const basiqDate = transactionDate ? transactionDate : postDate;

    const glossDate = new GlossDate();
    glossDate.timeZone = glossAccount.timezone ?? this.BASIQ_TIMEZONE;
    glossDate.setToDateObj(basiqDate);

    const toResponseObj = {
      quantity: Number(amount),
      /*TODO check if it is ok to set convrate to 1 or just remove it entirely*/
      convrate: 1,
      currency: glossAccount.currency,
      balance: Number(balance) !== 0 ? Number(balance) : "",
      symbol: glossAccount.currency,
      accountId: account,
      classifications,
      categories,
      description:
        enrich?.cleanDescription && enrich?.cleanDescription !== ""
          ? enrich.cleanDescription
          : description,
      date: glossDate,
      direction: direction === "credit" ? TransactionDirection.In : TransactionDirection.Out,
      kind: basiqTransaction.class,
      definition:
        basiqTransaction.status === "pending"
          ? TransactionStatusEnum.pending
          : TransactionStatusEnum.transaction,
      ...directionAndAmount,
    };

    const transactionResponse = new TransactionResponse(toResponseObj);
    return new Transaction(transactionResponse);
  }

  private directionAndQuantity(basiqTransaction: BasiqIoTransactionType) {
    if (basiqTransaction.direction === "credit") {
      return {
        direction: TransactionDirection.In,
        in: basiqTransaction.amount,
      };
    } else {
      return {
        direction: TransactionDirection.Out,
        out: Math.abs(Number(basiqTransaction.amount)),
      };
    }
  }

  async saveAccounts(): Promise<Book[]> {
    const basiqAccountResponse = await this.basiqConfigService.getAccounts();
    this.basiqAccounts = { data: basiqAccountResponse.accounts };
    return await this.basiqAccountService.getBasiqBasedAccounts(
      basiqAccountResponse.accounts as BasiqAccountType[]
    );
  }

  async saveSyncedTransactions(
    account: Book,
    isAddAlignment = false,
    basiqTransactions: { data: BasiqIoTransaction[] }
  ) {
    await this.transactionProcessingService.importValidatedTransactions(
      account,
      isAddAlignment,
      basiqTransactions
    );
  }

  async saveBasiqTransactionsOfAccount(account: Book, isAddAlignment = true) {
    try {
      await this.transactionProcessingService.saveBasiqTransactionsOfAccount(
        account,
        isAddAlignment
      );
    } catch (e) {
      this.log.info(JSON.stringify(account));
      this.log.error(e);
    } finally {
      this.records = null;
    }
  }

  private isJobIdsValid(jobIds: string, jobId: string) {
    if (jobIds === "null" || jobId === "null") {
      return false;
    }

    return jobIds && jobId;
  }

  private isJobIdsDefined(jobIds: string, jobId: string) {
    return jobIds && jobId;
  }

  async isCallBasiqAfterRedirect() {
    /*TODO clean the logic here so it make sense */
    const jobId = this.route.snapshot.queryParamMap.get("jobId");
    const jobIds = this.route.snapshot.queryParamMap.get("jobIds");
    const state = this.route.snapshot.queryParamMap.get("state");

    if (!this.isJobIdsDefined(jobIds, jobId)) {
      return false;
    }

    if (state && state === "toImport") {
      await this.clearUrlFromBasiqParams();
      return false;
    }

    if (this.isJobIdsValid(jobIds, jobId)) {
      await this.clearUrlFromBasiqParams();
      this.saveNewBasiqJobIdsToLocal(jobIds);
      return await this.basiqAuth.areJobsResolved(jobIds);
    } else if (this.isJobIdsDefined(jobIds, jobId)) {
      await this.router.navigate(["/gloss-settings/account"]);
    }

    return false;
  }

  private async clearUrlFromBasiqParams() {
    const currentURL = new URL(window.location.href);

    currentURL.searchParams.delete("jobId");
    currentURL.searchParams.delete("jobIds");

    const hashIndex = currentURL.href.indexOf("#");
    //TODO handle this better in case the route changes to something import-manager for eg
    const updatedURL = `${currentURL.href.substring(0, hashIndex)}#/gloss-settings/account`;

    history.replaceState({}, document.title, updatedURL);
  }

  private saveNewBasiqJobIdsToLocal(jobIds: string) {
    localStorage.setItem("basiqJobIds", jobIds);
  }

  async hasTransactions(): Promise<boolean> {
    this.sortedExistingTransactions = await this.dataRepositoryService.getAllTransactions();
    return !!this.sortedExistingTransactions.length;
  }

  getLastTransactionDate(basiqAccountInGloss: Book) {
    const latestAccountTransaction = this.sortedExistingTransactions.find(
      (transaction) => transaction.accountId === basiqAccountInGloss.id
    );
    return latestAccountTransaction
      ? this.dateFormat.getDateStringFromStamp(
          latestAccountTransaction.transactionDate.date.getTime()
        )
      : null;
  }
  async getAccountsMissingTransactions(basiqAccountInGloss: Book) {
    if (await this.hasTransactions()) {
      const lastTransactionDate = this.getLastTransactionDate(basiqAccountInGloss);
      return await this.getAllTransactions(lastTransactionDate, basiqAccountInGloss);
    }
  }

  private async getAllTransactions(lastTransactionsDate: string, account: Book) {
    const result: { data: BasiqIoTransaction[] } = { data: [] };

    let nextLoop = true;
    let nextUrlCall = null;
    while (nextLoop) {
      try {
        const transactions: any = await this.basiqConfigService.retrieveTransactions(
          lastTransactionsDate,
          account,
          nextUrlCall
        );

        if (!transactions) {
          throw new Error("Transactions not retrieved.");
        }

        nextUrlCall = transactions?.links?.next;
        result.data = result.data.concat(transactions.data);

        /*TODO : Because it is so slow when the data is more than 1000 , i put this break here . When it is all done I will fix it: @SINAN */
        nextLoop = nextUrlCall;
        //nextLoop = false;
      } catch (error) {
        nextLoop = false; // Exit the loop
      }
    }

    return result;
  }

  async refreshConnections(): Promise<BasiqJob[]> {
    const glossConnectionsResponse = await this.basiqAuth.getConnectionResponse();
    const jobs: BasiqJob[] = [];
    for (const connection of glossConnectionsResponse.connections) {
      const glossConnectionRefreshResponse = await this.basiqAuth.refreshConnection(connection);
      jobs.push(glossConnectionRefreshResponse.job);
    }

    return jobs;
  }

  async getConnectionOfUrl(connectionId: string): Promise<GlossConnectionResponse> {
    return await this.basiqConfigService.getConnectionById(connectionId);
  }

  async getBasiqInstitutionById(institutionId: string) {
    return await this.basiqConfigService.getInstitutionById(institutionId);
  }

  private async _institutionMapperMapInstitution(basiqInstitutionDetails: BasiqInstitution) {
    const countries = await this.institutionService.getCountryMasterList();
    const country = countries.find(
      (country) => country.name.toLowerCase() === basiqInstitutionDetails.country.toLowerCase()
    );
    const institutions = await this.institutionService.getInstitutionsMasterList();
    const institutionsOfCountry = institutions.filter(
      (testInstitution) => testInstitution.swift.countryCode === country.code
    );
    const mappedInstos = institutionsOfCountry.filter((institution) => {
      const insto =
        basiqInstitutionDetails.shortName.toLowerCase() === institution.name.toLowerCase() ||
        basiqInstitutionDetails.name.toLowerCase().includes(institution.name.toLowerCase());

      if (insto) {
        for (const accountType of institution.availableAccounts) {
          accountType.id = crypto.randomUUID();
        }

        return institution;
      }
    });

    if (mappedInstos.length) {
      mappedInstos[0].basiqId = basiqInstitutionDetails.id;

      return mappedInstos[0];
    } else {
      const newGlossInstitutionObj: any = {
        _name: basiqInstitutionDetails.name,
        _swift: {
          bankCode: null,
          countryCode: country.code,
          locationCode: null,
          branchCode: null,
        },
        _bic: {
          bankCode: null,
          countryCode: country.code,
          locationCode: null,
        },
        _basiqId: basiqInstitutionDetails.id,
      };

      return new Institution(new InstitutionResponse(newGlossInstitutionObj));
    }
  }
  async saveInstitutions() {
    const newInstitutions: Institution[] = [];
    const glossConnectionsResponse: GlossConnectionsResponse =
      await this.basiqConfigService.getConnections();
    for (const connection of glossConnectionsResponse.connections) {
      const glossConnectionResponse: GlossConnectionResponse = await this.getConnectionOfUrl(
        connection.id
      );
      const institution = glossConnectionResponse.connection.institution;
      const institutionExists = await this.dataRepositoryService.checkBasiqInstitution(institution);

      if (!institutionExists) {
        const glossInstitutionResponse = await this.getBasiqInstitutionById(institution.id);
        const institutionToSave = await this._institutionMapperMapInstitution(
          glossInstitutionResponse.institution
        );
        newInstitutions.push(institutionToSave);
      }
    }

    if (newInstitutions.length) {
      await this.dataRepositoryService.bulkCreateInstitutions(newInstitutions);
    }
  }

  /*TODO This method is a place holder for now . Once we got the flow from Lujane I will update it here */
  async connectToBasiqFromAccount() {
    if (this.basiqAuth.hasBasiqUser) {
      const userToken = await this.basiqAuth.getUserToken();
      window.location.href = `https://consent.basiq.io/home?&token=${userToken.token.access_token}&action=connect`;
    } else {
      await this.basiqAuth.authenticate();
      await this.basiqAuth.requestBasiqConsent();
    }
  }

  async checkBasiqUser() {
    return await this.basiqAuth.checkBasiqUser();
  }
}
