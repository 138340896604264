import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

import "./account-regions.component.css";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { TransactionBasiqImporter } from "@bitwarden/web-vault/app/importers/transaction-basiq-importer";
import { Country } from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
@Component({
  selector: "app-account-regions",
  templateUrl: "./account-regions.component.html",
  styles: ["account-regions.component.css"],
})
export class AccountRegionsComponent implements OnInit {
  protected filteredCountries: Country[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      closeDialogue: CallableFunction;
      openCreationOptionModal: CallableFunction;
      setLoading: CallableFunction;
    },
    private router: Router,
    private basiqImporter: TransactionBasiqImporter,
    private globalService: GlobalService,
    private institutionService: InstitutionService
  ) {}

  async ngOnInit() {
    this.filteredCountries = await this.institutionService.getCountryMasterList();
  }

  goBack() {
    this.data.closeDialogue();
    this.data.openCreationOptionModal();
  }
  async goWithBasiq() {
    try {
      this.data.closeDialogue();
      this.data.setLoading(true);

      await this.basiqImporter.checkBasiqUser();
      await this.basiqImporter.connectToBasiqFromAccount();
      this.data.setLoading(false);
    } catch (e) {
      this.globalService.showErrorMessage("error", "somethingWentWrong");
      this.data.setLoading(false);
    }

    /*this.router.navigate(["/gloss-settings/account/basiq-accounts"]).then(() => {
    });*/

    /*  this.router.navigate(['/import-management'],{ queryParams: { goTo: 'basiqImport' } }).then(()=>{
      this.data.closeDialogue()
    });*/
  }

  goWithPlaid() {
    /* direct user to ...settings/accounts/plaid/ ? */
  }
}
