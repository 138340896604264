import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from "@angular/core";
import { FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import "./customer-support-box.component.scss";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { TokenService } from "@bitwarden/common/abstractions/token.service";
import { SupportConfigService } from "@bitwarden/web-vault/app/services/api/support-config.service";

const MAX_MSG_LENGTH = 150;
const CHARACTER_COUNT_PER_ROW = 45;
const MAX_ROW_NUM = 8;

@Component({
  selector: "app-customer-support-box",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatIconModule, MatButtonModule],
  templateUrl: "./customer-support-box.component.html",
  styles: ["customer-support-box.component.scss"],
})
export class CustomerSupportBoxComponent implements OnInit {
  webError: string | null = null;
  firstName: string;
  email: string;
  userId: string;
  formSendSuccess = false;
  btnMouseDown_submit = false;
  btnMouseDown_minimiseForm = false;
  submitClicked = false;
  requestProcessing = false;
  supportBoxHeader = "Help & Support";
  rows = 4;
  @Input() helpOpen: boolean;
  @Input() prefilledMessage = "";
  @Output() changeHelpOpen = new EventEmitter<boolean>();

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      supportBoxHeader: string;
      dialogOpen: true;
      closeDialogue: CallableFunction;
    },
    private formBuilder: FormBuilder,
    private tokenService: TokenService,
    private supportConfigService: SupportConfigService,
    private log: LogService
  ) {}

  supportEnquiry = this.formBuilder.group(
    {
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      message: ["", Validators.required],
    },
    { updateOn: "blur" }
  );

  async ngOnInit(): Promise<void> {
    this.supportBoxHeader = this.data?.supportBoxHeader || "Help & Support";
    this.helpOpen = this.data?.dialogOpen || this.helpOpen;
    this.firstName = await this.tokenService.getName();
    this.email = await this.tokenService.getEmail();
    this.userId = await this.tokenService.getUserId();
    if (this.prefilledMessage && this.prefilledMessage.length > MAX_MSG_LENGTH) {
      const rowCount = Math.ceil(this.prefilledMessage.length / CHARACTER_COUNT_PER_ROW);
      this.rows = rowCount < MAX_ROW_NUM ? rowCount + 1 : MAX_ROW_NUM;
    }
    this.autoFillUserDetail();
  }

  autoFillUserDetail() {
    this.supportEnquiry.patchValue({
      firstName: this.firstName,
      email: this.email,
      message: this.prefilledMessage,
    });
  }

  closeHelpBox() {
    this.changeHelpOpen.emit(false);
    this.helpOpen = false;
    this.submitClicked = false;
    this.supportEnquiry.markAsUntouched();
    if (this.data?.closeDialogue) {
      this.data.closeDialogue();
    }
  }

  messageSendSuccessClose() {
    this.closeHelpBox();
    this.formSendSuccess = false;
    this.supportEnquiry.controls.lastName.markAsUntouched();
  }

  async sendMessage() {
    this.supportEnquiry.markAllAsTouched();

    if (this.supportEnquiry.valid) {
      this.requestProcessing = true;
      try {
        const result = this.supportEnquiry.value;
        const payload = this.supportConfigService.setPayload(result);
        await this.supportConfigService.sendSupport(payload);
      } catch (e) {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.requestProcessing = false;
        this.formSendSuccess = true;
        this.submitClicked = false;
        this.messageSendSuccessClose();
        this.supportEnquiry.reset();
        this.autoFillUserDetail();
      }
    }
  }
}
