<ng-container *ngIf="!(stepsService.getIsWizardInProgress() | async)">
  <div *ngIf="!showGraph && !showSpinner" class="no-data-container"></div>
  <div *ngIf="!hasEarlyAccess">
    <app-coming-soon-page></app-coming-soon-page>
  </div>
  <main-progress-bar *ngIf="!(mainProgressBar.getIsLoadingCompleted() | async)"></main-progress-bar>
  <div class="content" *ngIf="showGraph && hasEarlyAccess">
    <app-primary-summary-graph
      [graphData]="graphData"
      [scenarioData]="scenarioData"
      [defaultDates]="defaultDates"
      [dashboardParameters]="dashboardParameters"
      [defaultGranularity]="defaultGranularity"
      [granularityOptions]="granularityOptions"
      (handleSpinner)="handleSpinner($event)"
      (callFilter)="callFilter($event)"
      (callScenario)="callScenario($event)"
    ></app-primary-summary-graph>
    <app-filtered-transactions-table-card></app-filtered-transactions-table-card>
  </div>
</ng-container>
