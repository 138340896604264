<div
  class="tw-flex tw-flex-col tw-gap-6 tw-rounded-lg tw-bg-white tw-p-6 md:tw-h-full md:tw-w-full md:tw-p-4"
>
  <nav
    class="tw-relative tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-gap-4"
    (click)="redirectToSettings()"
  >
    <div class="md:tw-hidden">
      <svg class="tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#left-arrow" />
      </svg>
    </div>
    <div
      class="tw-hidden tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-bg-neutral-50 tw-shadow-button md:tw-flex"
    >
      <svg class="tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#left-arrow" />
      </svg>
    </div>
    <span class="tw-text-xl tw-font-semibold tw-text-neutral-800 md:tw-hidden">{{
      "myDangerZone" | i18n
    }}</span>
    <div class="tw-absolute tw-hidden tw-w-full tw-items-center tw-justify-center md:tw-flex">
      <span class="tw-text-xl tw-font-semibold tw-text-neutral-800">{{
        "myDangerZone" | i18n
      }}</span>
    </div>
  </nav>

  <div class="tw-flex tw-flex-col tw-gap-6">
    <div
      class="tw-flex tw-h-auto tw-w-[80%] tw-min-w-[350px] tw-flex-col tw-rounded-lg tw-border-[2px] tw-border-solid tw-border-danger tw-py-6 tw-px-6 md:tw-w-full md:tw-min-w-[300px] md:tw-py-4"
    >
      <div class="tw-flex tw-gap-2">
        <h6>
          <svg class="tw-h-5 tw-w-5">
            <use xlink:href="images/sprite.svg#info-danger" />
          </svg>
        </h6>
        <div class="tw-flex tw-flex-col">
          <h6 class="tw-whitespace-nowrap tw-font-semibold tw-text-danger">
            {{ "dangerZone" | i18n }}
          </h6>
          <div>
            <p class="tw-mb-0">{{ "dangerZoneDesc" | i18n }}</p>
          </div>
        </div>
      </div>
    </div>

    <div *appRoleScope="RoleScope.GLOSS">
      <div class="tw-whitespace-nowrap tw-font-medium">{{ "deauthorizeSessions" | i18n }}</div>
      <div>
        <button
          (click)="deauthorizeSessions()"
          class="tw-flex tw-h-12 tw-min-w-[190px] tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-full tw-border-none tw-bg-neutral-50 tw-p-6 tw-font-medium tw-text-danger tw-shadow-button hover:tw-font-medium"
        >
          {{ "deauthorizeSessions" | i18n }}
        </button>
      </div>
    </div>

    <div *appRoleScope="RoleScope.BETA_ACCESS">
      <div class="tw-whitespace-nowrap tw-font-medium">{{ "purgeVault" | i18n }}</div>
      <div>
        <button
          (click)="purgeVault()"
          class="tw-flex tw-h-12 tw-min-w-[190px] tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-full tw-border-none tw-bg-neutral-50 tw-p-6 tw-font-medium tw-text-danger tw-shadow-button hover:tw-font-medium"
        >
          {{ "purgeVault" | i18n }}
        </button>
      </div>
    </div>

    <div *appRoleScope="RoleScope.GLOSS">
      <div class="tw-whitespace-nowrap tw-font-medium">{{ "deleteAccount" | i18n }}</div>
      <div>
        <button
          (click)="deleteAccount()"
          class="tw-flex tw-h-12 tw-min-w-[190px] tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-full tw-border-none tw-bg-neutral-50 tw-p-6 tw-font-medium tw-text-danger tw-shadow-button hover:tw-font-medium"
        >
          {{ "deleteAccount" | i18n }}
        </button>
      </div>
    </div>
  </div>
</div>
