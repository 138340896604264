import "./dash-transaction-table.scss";
import { SelectionModel } from "@angular/cdk/collections";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import {
  ColDef,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from "ag-grid-community";
import {
  IDatasource,
  IGetRowsParams,
  LicenseManager,
  RowModelType,
  SortModelItem,
} from "ag-grid-enterprise";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { NoRowsOverlayComponentOfDashTable } from "@bitwarden/web-vault/app/components/cell-renderers/no-rows-overlay-component-of-dash-table/no-rows-overlay-component-of-dash-table.component";
import { transactionColumnDefinitions } from "@bitwarden/web-vault/app/components/dash-transaction-table/ag-grid-column-definitions";
import { getRightClickButtons } from "@bitwarden/web-vault/app/components/dash-transaction-table/ag-grid-right-click-buttons";
import { DashboardParameters } from "@bitwarden/web-vault/app/components/dashboard-selector/dashboard-selector.component";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { RoleAccessData } from "@bitwarden/web-vault/app/models/data/role-access.data";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction.view";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { ColumnStateService } from "@bitwarden/web-vault/app/services/dashboard/columnState-service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { DataTransformer } from "@bitwarden/web-vault/app/services/dto/data-transformer";
import { RoleAccessService } from "@bitwarden/web-vault/app/services/permission/role-access.service";
import { SharedService } from "@bitwarden/web-vault/app/shared/sharedAppEvent.service";
import { ExperimentalMode } from "@bitwarden/web-vault/app/shared/utils/feature-flag/experimental-mode";
import { getDailyBalance } from "@bitwarden/web-vault/app/shared/utils/helper-balance";
import { HelperPagination } from "@bitwarden/web-vault/app/shared/utils/helper.pagination";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper.preference";
import { LinkTransaction } from "@bitwarden/web-vault/app/shared/utils/helper.transactions/link-transaction";
import { ValidatorResponseTypes } from "@bitwarden/web-vault/app/validators/link.transactions/link-validator";

/* Set license */
LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-063300}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Ironfly_Technologies_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GLOSS_Vault}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GLOSS_Vault}_need_to_be_licensed___{GLOSS_Vault}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{11_July_2025}____[v3]_[01]_MTc1MjE4ODQwMDAwMA==78426cc29fbc2c22bf97224bbd624392"
);

/* Core Grid CSS */
import "ag-grid-community/styles/ag-grid.css";
/* Quartz Theme Specific CSS */
import "ag-grid-community/styles/ag-theme-quartz.css";

export type ToasterType = "success" | "error" | "warning";

@Component({
  selector: "app-dash-transaction-table",
  templateUrl: "dash-transaction-table.component.html",
  styles: ["dash-transaction-table.scss", "ag-grid.css", "ag-theme-quartz.css"],
})

/*TODO clean this component from old mat-table codes and remove the old mat-table component if not used */
export class DashTransactionTableComponent
  extends LinkTransaction
  implements OnInit, OnChanges, OnDestroy
{
  protected readonly GlossDate = GlossDate;
  protected readonly NoRowsOverlayComponentOfDashTable = NoRowsOverlayComponentOfDashTable;
  private unsubscribe$ = new Subject<void>();
  private scenarioIndex = 2;
  private paginationHelper = new HelperPagination();
  private gridFirstDataRendered = false;

  private roleAccess: RoleAccessData;
  private isBetaUser = false;

  paginationPageSize = 25;
  paginationPageSizeSelector = [25, 50, 100];
  selectedTransactions: Transaction[] = [];
  linkButtonEnabled = false;
  autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = { type: "fitGridWidth", defaultMinWidth: 100 };
  columnMenu: "new" = "new";
  mobilCellContextMenu = false;
  rowSelection: "multiple" | "single" | undefined;
  rowModelType: RowModelType = this.isPaginationEnabled() ? "clientSide" : "infinite";
  cacheBlockSize = 5;
  infiniteInitialRowCount = 0;

  // Column Definitions: Defines the columns to be displayed.
  colDefs: ColDef[] = transactionColumnDefinitions;
  context = { componentParent: this };
  // Row class rules for custom styling
  rowClassRules = {
    "ag-row-selected": (params: any) => params.node.selected,
  };

  @Input() transactionsView: Array<TransactionView>;
  @Input() transactions: Array<Transaction>;
  @Input() groupBalances: BalanceGrouping;
  @Input() mockAccounts: Array<Book>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChildren(MatCheckbox) checkboxes: QueryList<MatCheckbox>;
  @ViewChild("agGrid") agGrid: AgGridAngular;

  gridOptions = this.isPaginationEnabled()
    ? {
        pagination: this.isPaginationEnabled(),
        paginationPageSize: this.paginationPageSize,
        paginationPageSizeSelector: this.paginationPageSizeSelector,
      }
    : {
        cacheBlockSize: this.cacheBlockSize,
        infiniteInitialRowCount: this.infiniteInitialRowCount,
      };

  private destroy$ = new Subject<void>();
  dashboardParameters: DashboardParameters;
  protected readonly top = top;
  mouseOnTd = false;
  mouseOnTooltip = false;
  hoveredTransaction: TransactionView;
  showTooltipBoolean = false;
  gridApi: any;
  xPosition = 0;
  yPosition = 0;
  selectedTransactionId: string;
  dataSource: MatTableDataSource<TransactionView>;
  selection: SelectionModel<any>;
  initialised = false;
  linkedId = "";
  baseCurrency = "";
  displayedColumns: string[] = [
    "link",
    "account",
    "transactionDate",
    "categories",
    "classifications",
    "description",
    "out",
    "in",
    "out_normalized",
    "in_normalized",
    "aggregate",
    "balance",
  ];
  showRevaluations = true;
  showEstimates = true;
  isActionExperimental: boolean;
  isHideEstimateExperimental: boolean;
  indexViewMap: Map<string, number> = new Map();
  helpOpen = false;
  prefilledMessage = "I would like to join the early access program…";

  constructor(
    private logService: LogService,
    public i18nService: I18nService,
    private transactionService: TransactionService,
    private dataRepositoryService: DataRepositoryService,
    private platformUtilsService: PlatformUtilsService,
    private router: Router,
    private helperPreference: HelperPreference,
    protected experimentalMode: ExperimentalMode,
    private cdRef: ChangeDetectorRef,
    private dashboardService: DashboardService,
    private globalService: GlobalService,
    private roleAccessService: RoleAccessService,
    private sharedService: SharedService,
    private deviceService: DeviceDetectorService,
    private columnStateService: ColumnStateService
  ) {
    super();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isLinkButtonEnabled() {
    this.linkButtonEnabled = this.linkedTransactions?.length > 1;
    return this.linkButtonEnabled;
  }

  async getAccount(p: any) {
    return "Account";
  }

  sizeColumnsToFit() {
    if (!this.isMobile()) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  applyAutoSize() {
    if (this.gridFirstDataRendered) {
      this.sizeColumnsToFit();
      this.saveColumnState();
    }
  }

  addColumnVisibleListener() {
    if (this.gridFirstDataRendered) {
      this.gridApi.addEventListener("columnVisible", () => this.applyAutoSize());
    }
  }

  getColumnState() {
    return this.columnStateService.getColumnState();
  }

  saveColumnState() {
    return this.columnStateService.saveColumnState(this.gridApi);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    const columnState = this.getColumnState();
    if (columnState) {
      this.gridApi.applyColumnState({ state: columnState });
    } else {
      this.gridApi.applyColumnState({
        state: [{ colId: "transactionDate", sort: "desc" }],
      });
    }
    if (this.dashboardParameters) {
      this.updateColumnDefinitions(this.dashboardParameters);
      this.dashboardParameters = null;
    }
    this.rowSelection = this.isMobile() ? "multiple" : "single";
    this.hideActionColumn();

    this.gridApi.addEventListener("gridSizeChanged", () => {
      this.applyAutoSize();
    });
    this.updateData(this.transactionsView);
  }

  onFirstDataRendered() {
    this.gridFirstDataRendered = true;
    this.addColumnVisibleListener();
    this.applyAutoSize();
  }

  sortDataMobile(sortModel: SortModelItem[], data: any[]) {
    const sortPresent = sortModel && sortModel.length > 0;
    if (!sortPresent) {
      return data;
    }
    // do an in memory sort of the data, across all the fields
    const resultOfSort = data.slice();
    resultOfSort.sort(function (a, b) {
      for (let k = 0; k < sortModel.length; k++) {
        const sortColModel = sortModel[k];
        const valueA = a[sortColModel.colId];
        const valueB = b[sortColModel.colId];
        // this filter didn't find a difference, move onto the next one
        if (valueA == valueB) {
          continue;
        }
        const sortDirection = sortColModel.sort === "asc" ? 1 : -1;
        if (valueA > valueB) {
          return sortDirection;
        } else {
          return sortDirection * -1;
        }
      }
      // no filters found a difference
      return 0;
    });
    return resultOfSort;
  }

  updateData(data: any[]) {
    const datasource: IDatasource = {
      rowCount: undefined, // behave as infinite scroll
      getRows: (params: IGetRowsParams) => {
        const sortData = this.sortDataMobile(params.sortModel, data);
        // take a slice of the total rows
        const rowsThisPage = sortData.slice(params.startRow, params.endRow);
        // if on or after the last page, work out the last row.
        let lastRow = -1;
        if (data.length <= params.endRow) {
          lastRow = data.length;
        }
        // call the success callback
        params.successCallback(rowsThisPage, lastRow);
      },
    };

    !this.isPaginationEnabled()
      ? this.gridApi?.setGridOption("datasource", datasource)
      : this.gridApi?.setGridOption("rowData", data);
    if (this.gridApi && data.length === 0) {
      this.gridApi?.showNoRowsOverlay();
    } else {
      this.gridApi?.hideOverlay();
    }
  }

  getContextMenuItems = (params: any) => {
    return getRightClickButtons(params, this.isBetaUser);
  };

  async rightClickDeleteTransactions(transactionToDelete: Transaction) {
    const selectedTransactions = this.selectedTransactions.filter(
      (transaction: Transaction) => transaction.id !== transactionToDelete.id
    );
    this.selectedTransactions = [transactionToDelete];
    await this.deleteTransaction();
    this.selectedTransactions = selectedTransactions;
  }

  async onCellClicked(event: any) {
    if (this.isMobile()) {
      if (!this.mobilCellContextMenu) {
        event.node.setSelected(true);
        const params = {
          x: event.event.clientX,
          y: event.event.clientY,
          rowNode: event.node,
          column: event.column,
          value: event.value,
        };
        this.gridApi.showContextMenu(params);
        await this.addSelectTransaction(event.data);
        this.mobilCellContextMenu = true;
      } else {
        this.selectedTransactions = [];
        this.mobilCellContextMenu = false;
      }
    }
  }

  async onCellRightClicked(event: any) {
    event.node.setSelected(true);
    if (this.isMobile()) {
      await this.addSelectTransaction(event.data);
    }
  }

  async addSelectTransaction(view: TransactionView) {
    if (!this.selectedTransactions.some((transaction) => transaction.id === view.id)) {
      await this.checkToggle(view, true);
    } else {
      await this.checkToggle(view, false);
    }
  }

  async ngOnInit() {
    this.initialised = true;
    this.baseCurrency = await this.helperPreference.getBaseCurrency();
    this.isActionExperimental = await this.isActionsExperimental();
    this.isHideEstimateExperimental = await this.isEstimateExperimental();

    if (this.isActionExperimental) {
      this.displayedColumns.push("actions");
    }

    this.hoveredTransaction = this.transactionsView[0];
    this.selectedTransactionId = this.transactionsView[0]?.id;

    this.dashboardService.dashboardConfig$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dashboardParameters) => {
        if (dashboardParameters) {
          this.dashboardParameters = dashboardParameters;
          this.updateColumnDefinitions(dashboardParameters);
        }
      });

    this.roleAccess = this.roleAccessService.getRoleAccess();
    const scope = this.roleAccess.getScope();
    this.isBetaUser = scope.includes(RoleScope.BETA_ACCESS);
  }

  updateColumnDefinitions(params: DashboardParameters) {
    this.gridApi?.applyColumnState({
      state: [{ colId: "categories", hide: params.type !== "transactionOnly" }],
    });

    // Subscribe to observable to update grid options dynamically
    this.dashboardService._scenarioIndex$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((scenarioIndex) => {
        this.scenarioIndex = scenarioIndex;
      });
  }

  hideActionColumn() {
    this.gridApi.setColumnsVisible(["isSelected"], false);
    if (this.isMobile()) {
      this.gridApi.setColumnsVisible(["isSelected"], false);
    } else {
      this.gridApi.setColumnsVisible(["isSelected"], true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.transactionsView) {
      this.setupDataSource();
      this.updateData(this.transactionsView);
    }
  }

  onMouseEnterTooltip(showTooltip: boolean) {
    this.mouseOnTooltip = true;
    this.showTooltipBox(true);
  }

  onMouseLeaveTooltip(showTooltip: boolean) {
    this.mouseOnTooltip = false;
    setTimeout(() => {
      if (!this.mouseOnTooltip && !this.mouseOnTd) {
        this.showTooltipBox(false);
      }
    });
  }

  handleHelpBoxOpen($event: boolean) {
    this.helpOpen = $event;
  }

  openHelp(event: Event, prefilledMessage: string) {
    this.prefilledMessage = prefilledMessage;
    this.helpOpen = true;
  }

  showTooltipBox(showTooltip: boolean) {
    this.showTooltipBoolean = showTooltip;
    if (!showTooltip) {
      this.yPosition = 0;
      this.xPosition = 0;
    }
  }

  async checkToggle(transactionView: TransactionView, checked: boolean) {
    const isReval = this.isRevaluation(transactionView);
    if (!this.isValidToLink(isReval)) {
      this.check[transactionView.id] = false;
      await this.showToaster(isReval, this.platformUtilsService, this.i18nService);
      //event.source.toggle();
      return;
    }

    const isSelected = checked;
    const linkedViews = this.transactionsView.filter((view, index) => {
      return transactionView.linkedTo.includes(view.id) || view.id === transactionView.id;
    });
    const uniqueIds: Set<string> = new Set();
    const allLinkViews = linkedViews.filter((linkView) => {
      if (!uniqueIds.has(linkView.id)) {
        uniqueIds.add(linkView.id);
        return true;
      }

      return false;
    });
    const linkTransactions = DataTransformer.castToTransactionArray(allLinkViews);
    for (const trans of linkTransactions) {
      if (isSelected) {
        if (trans.id !== transactionView.id) {
          this.check[trans.id] = true;
        }
        this.selectToLink(trans);
      } else {
        if (trans.id !== transactionView.id) {
          this.check[trans.id] = false;
        }
        this.unselectToLink(trans);
      }
    }

    this.isLinkButtonEnabled();

    if (checked) {
      this.selectedTransactions.push(transactionView.baseTransaction);
    } else {
      this.selectedTransactions = this.selectedTransactions.filter(
        (transaction) => transaction.id !== transactionView.baseTransaction.id
      );
    }
  }

  handleEdit(transactionView: TransactionView) {
    this.router.navigate(["/add-edit-transaction"], {
      queryParams: { transactionId: transactionView.id },
    });
  }

  async refreshDataSource() {
    const transactions = await this.dataRepositoryService.getAllTransactions();
    this.dataSource.data = this.transactionsView.map((transactionView) => {
      if (this.linkIds.includes(transactionView.id)) {
        const linkedTransaction = transactions.find(
          (transaction) => transaction.id === transactionView.id
        );
        if (linkedTransaction) {
          return transactionView.update(linkedTransaction);
        } else {
          return transactionView;
        }
      } else {
        return transactionView;
      }
    });

    this.transactionsView = this.dataSource.data;
  }

  async linkConversion() {
    try {
      this.loading = true;
      const isConversion = this.isConversion(this.linkedTransactions);
      if (!this.isValidToLink(isConversion)) {
        await this.showToaster(isConversion, this.platformUtilsService, this.i18nService);
        return;
      }
      await this.saveLink(isConversion);
    } catch (e) {
      this.logService.error(e.message);
    }
  }

  async linkTransfer() {
    try {
      this.loading = true;
      const isTransfer = this.isTransfer(this.linkedTransactions);
      if (!this.isValidToLink(isTransfer)) {
        await this.showToaster(isTransfer, this.platformUtilsService, this.i18nService);
        return;
      }
      await this.saveLink(isTransfer);
    } catch (e) {
      this.logService.error(e.message);
    }
  }

  async unlinkTransactions() {
    try {
      const { unlinkTransactions, newTransactionsView } = await this.removeLinkage(
        this.transactionsView
      );
      this.transactionsView = newTransactionsView;
      await this.save(this.UNLINK_RESPONSE, unlinkTransactions);
      this.loading = false;
    } catch (e) {
      this.logService.error(e.message);
    }
  }

  async resetColumns() {
    this.gridApi.resetColumnState();
  }

  async deleteTransaction() {
    try {
      if (this.selectedTransactions.length === 0) {
        this.globalService.showMessage("info", "", "selectAtLeastOneTransaction");
        return;
      }

      const isValidToDelete = this.validateDelete(this.selectedTransactions);
      if (!this.isRealTransactions(this.selectedTransactions)) {
        await this.showToaster(isValidToDelete, this.platformUtilsService, this.i18nService);
        return;
      }

      const confirm = await this.globalService.showDialogue(
        "areYouSureToDeleteTransactions",
        "warning"
      );

      if (confirm) {
        this.loading = true;
        const areDeleted = await this.transactionService.bulkDelete(this.selectedTransactions);
        if (areDeleted) {
          await this.showToaster(isValidToDelete, this.platformUtilsService, this.i18nService);

          this.transactionsView = this.transactionsView.filter(
            (transactionView) =>
              !this.linkedTransactions.some(
                (linkedTransaction) => linkedTransaction.id === transactionView.id
              )
          );
        } else {
          isValidToDelete.status = "warning";
          isValidToDelete.messageId = "missingTransactionsToDelete";
          await this.showToaster(isValidToDelete, this.platformUtilsService, this.i18nService);
        }
        await this.dashboardService.loadDashboardData();
        this.sharedService.triggerApplyEvent();
        this.resetProps();

        this.loading = false;
      }
    } catch (e) {
      this.logService.error(e.message);
    }
  }

  private async saveLink(response: ValidatorResponseTypes) {
    await this.save(response, this.linkedTransactions);
  }

  private async save(response: ValidatorResponseTypes, transactions: Transaction[]) {
    for (const transaction of transactions) {
      await this.transactionService.update(transaction);
    }

    await this.refreshDataSource();
    await this.showToaster(response, this.platformUtilsService, this.i18nService);
    this.resetProps();
  }

  setCheckBoxIndex() {
    if (this.transactionsView.length) {
      this.transactionsView.forEach((view, index) => {
        this.indexViewMap.set(view.id, index);
        this.check[view.id] = false;
        if (
          this.selectedTransactions.some(
            (transaction) => transaction.id === view.baseTransaction.id
          )
        ) {
          view.isSelected = true;
        }
      });
    }
  }

  private setupDataSource() {
    this.setCheckBoxIndex();
    const transactionsView = getDailyBalance(this.transactionsView, this.groupBalances);

    if (this.mockAccounts?.length) {
      transactionsView.forEach((transaction) => transaction.setMockAccount(this.mockAccounts));
    }

    // Filter the data based on showRevaluations
    const filteredData = this.filterDataSource(
      transactionsView,
      this.showRevaluations,
      this.showEstimates
    );

    this.dataSource = new MatTableDataSource<TransactionView>(filteredData);
    this.dataSource.paginator = this.paginator;
    //this.dataSource.sortingDataAccessor = this.sortColumns;

    /** Set the default sorting direction for the 'transactionDate' column */
    if (this.sort) {
      this.sort.start = "desc";
      this.sort.active = "transactionDate";
      this.sort.direction = "desc";
      this.dataSource.sort = this.sort;
    }
  }

  private filterDataSource(
    data: TransactionView[],
    showRevaluations: boolean,
    showEstimates: boolean
  ): TransactionView[] {
    if (showRevaluations) {
      // When showRevaluations is true, no filtering is applied based on revalTransaction
      return showEstimates ? data : data.filter((item) => !item.estimateTransaction);
    } else {
      // When showRevaluations is false, show items with revalTransaction as false and showEstimates based on the specified value
      return data.filter(
        (item) => !item.revalTransaction && (showEstimates ? true : !item.estimateTransaction)
      );
    }
  }

  async isActionsExperimental() {
    return await this.experimentalMode.isExperimental("transaction", "action");
  }

  async isEstimateExperimental() {
    return await this.experimentalMode.isExperimental("transaction", "toggleEstimate");
  }

  isMobile() {
    return this.deviceService.isMobile();
  }

  isPaginationEnabled() {
    return this.paginationHelper.isPaginationEnabled();
  }
}
