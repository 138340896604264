import { GlossRouteEnum } from "../models/enum/navigation.enum";
import { GlossRoute, GlossRoutes } from "../models/types/navigation.types";

/**
 * Represents the dashboard route.
 * @type {GlossRoute}
 */
const dashboardRoute: GlossRoute = { type: "button", i18Mapper: null };

/**
 * Represents the account management route.
 * @type {GlossRoute}
 */
const syncRoute: GlossRoute = {
  type: "text",
  i18Mapper: "sideMenuSync",
};

/**
 * Represents the account management route.
 * @type {GlossRoute}
 */
const accountRoute: GlossRoute = {
  type: "text",
  i18Mapper: "manageAccounts",
};

/**
 * Represents the categories management route.
 * @type {GlossRoute}
 */
const categoriesRoute: GlossRoute = {
  type: "text",
  i18Mapper: "manageCategories",
};

/**
 * Represents the classifications management route.
 * @type {GlossRoute}
 */
const classificationRoute: GlossRoute = {
  type: "text",
  i18Mapper: "manageClassifications",
};

/**
 * Represents the institutions management route.
 * @type {GlossRoute}
 */
const institutionRoute: GlossRoute = {
  type: "text",
  i18Mapper: "manageInstitutions",
};

/**
 * Represents the imports management route.
 * @type {GlossRoute}
 */
const importRoute: GlossRoute = {
  type: "text",
  i18Mapper: "manageImports",
};

/**
 * Represents the references management route.
 * @type {GlossRoute}
 */
const referenceRoute: GlossRoute = {
  type: "text",
  i18Mapper: "manageReferences",
};

/**
 * Represents the estimates management route.
 * @type {GlossRoute}
 */
const estimatesRoute: GlossRoute = {
  type: "text",
  i18Mapper: "manageEstimates",
};

/**
 * Represents the settings management route.
 * @type {GlossRoute}
 */
const settingsRoute: GlossRoute = {
  type: "text",
  i18Mapper: "managePreferences",
};

/**
 * Represents the settings subscription route.
 * @type {GlossRoute}
 */
const subscriptionRoute: GlossRoute = {
  type: "text",
  i18Mapper: "mySubscription",
};

/**
 * Represents the settings security route.
 * @type {GlossRoute}
 */

/**
 * Represents the collection of all routes in the application.
 * @type {GlossRoutes}
 */
export const glossRoutes: GlossRoutes = {
  [GlossRouteEnum.dashboard]: dashboardRoute,
  [GlossRouteEnum.account]: accountRoute,
  [GlossRouteEnum.categories]: categoriesRoute,
  [GlossRouteEnum.classifications]: classificationRoute,
  [GlossRouteEnum.institutions]: institutionRoute,
  [GlossRouteEnum.import]: importRoute,
  [GlossRouteEnum.reference]: referenceRoute,
  [GlossRouteEnum.estimates]: estimatesRoute,
  [GlossRouteEnum.settings]: settingsRoute,
  [GlossRouteEnum.subscription]: subscriptionRoute,
  [GlossRouteEnum.sync]: syncRoute,
};
