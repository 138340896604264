import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-user-access-page",
  templateUrl: "user-access-page.component.html",
})
export class UserAccessPageComponent implements OnInit, OnDestroy {
  @Output() toggleDisplay = new EventEmitter<void>();
  isPremiumUser: boolean;
  private destroy$ = new Subject<void>();

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.isPremiumUser = params["isPremiumUser"] === "true";
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  redirectToSettings() {
    this.toggleDisplay.emit();
  }
}
