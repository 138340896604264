import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";

import { StringToNumberPreference } from "../../types/PrefereneceTypes";
import { PreferenceResponse } from "../response/preference.response";

export type SyncStatusType = {
  startDate: string;
  endDate: string | undefined;
  symbols: string[];
};
export type DataProviderName = "fixer" | "instrumentStore" | "basiq" | "plaid";

export type SyncStatusCollectionType = Record<DataProviderName, SyncStatusType>;

export type PreferenceTypeKey = Exclude<keyof Preference, keyof ModelValidator>;

export class Preference extends ModelValidator {
  private readonly __v = 1;
  private readonly _id: string;

  baseCurrency: string;
  timeZone: string;
  dateFormat: string;
  mode: string;
  weekDayStart: StringToNumberPreference;
  YearMonthStart: StringToNumberPreference;
  monthDayStart: number;
  syncStatus: SyncStatusCollectionType;

  get id(): string {
    return this._id;
  }
  get currency(): string {
    return this.baseCurrency;
  }

  split: number;

  constructor(response: PreferenceResponse) {
    super();
    if (response == null) {
      return;
    }

    this.checkVersion(this.__v, response.__v);
    this._id = response.id ? response.id : crypto.randomUUID();
    this.baseCurrency = response.baseCurrency;
    this.timeZone = response.timeZone;
    this.dateFormat = response.dateFormat;
    this.mode = response.mode;
    this.weekDayStart = response.weekDayStart;
    this.monthDayStart = response.monthDayStart;
    this.YearMonthStart = response.YearMonthStart;
    this.syncStatus = response.syncStatus;
  }
}
