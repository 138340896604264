<app-spinner [showSpinner]="accessLoader"></app-spinner>
<app-spinner [showSpinner]="spinnerLoader"></app-spinner>
<app-progress-bar
  *ngIf="loading && !inWizard"
  [isStart]="loading"
  [parentLabel]="label"
  [infoText]="progressInfoText"
  [isInfo]="isInfo"
></app-progress-bar>
<div class="content">
  <div class="tw-flex" id="account-table-actions-container" *ngIf="!isMobile()">
    <div class="tw-ml-auto tw-flex" *appRoleScope="RoleScope.GLOSS">
      <!--        <button class="account-table-action-button" type="button" (click)="createConnector()">
            <mat-icon class="transaction-action-button-icon" aria-hidden="true">autorenew</mat-icon>
            createConnector
          </button>

          <button class="account-table-action-button" type="button" (click)="deleteConnector()">
            <mat-icon class="transaction-action-button-icon" aria-hidden="true">delete</mat-icon>
            deleteConnector
          </button>-->

      <button
        [disabled]="!isEntry()"
        [ngClass]="{ 'tw-opacity-60': !isEntry() }"
        class="account-table-action-button"
        type="button"
        (click)="addBalanceSelected()"
      >
        <mat-icon class="transaction-action-button-icon" aria-hidden="true"
          >account_balance_wallet</mat-icon
        >
        {{ "addEntry" | i18n }}
      </button>

      <button
        [disabled]="!isEdit()"
        [ngClass]="{ 'tw-opacity-60': !isEdit() }"
        class="account-table-action-button"
        type="button"
        (click)="editSelected()"
      >
        <mat-icon class="transaction-action-button-icon" aria-hidden="true">edit</mat-icon>
        {{ "edit" | i18n }}
      </button>

      <button
        [disabled]="!isDelete()"
        [ngClass]="{ 'tw-opacity-60': !isDelete() }"
        class="account-table-action-button"
        type="button"
        (click)="deleteSelected()"
      >
        <mat-icon class="transaction-action-button-icon" aria-hidden="true">delete</mat-icon>
        {{ "delete" | i18n }}
      </button>
      <!-- todo this is the old sync way, removing as the Auto Sync is temporary moved on the side -->
      <!--      <button-->
      <!--        *appRoleScope="RoleScope.DATA_AGGREGATOR"-->
      <!--        class="account-table-action-button"-->
      <!--        type="button"-->
      <!--        (click)="syncAllAccounts()"-->
      <!--      >-->
      <!--        <mat-icon class="transaction-action-button-icon" aria-hidden="true">sync</mat-icon>-->
      <!--        Sync All -->
      <!--      </button>-->

      <button
        class="account-table-action-button"
        type="button"
        (click)="openCreationOptionsModal()"
      >
        <mat-icon class="transaction-action-button-icon" aria-hidden="true">add_circle</mat-icon>
        Add New
      </button>
    </div>
  </div>
  <div class="tw-flex tw-justify-between" *ngIf="isMobile()">
    <button
      *appRoleScope="RoleScope.DATA_AGGREGATOR"
      class="account-table-action-button tw-mb-5 tw-flex tw-items-center tw-rounded-xl tw-border-none tw-px-10 tw-py-2 tw-shadow-inner"
      type="button"
      (click)="syncAllAccounts()"
    >
      <div class="tw-mr-3 tw-flex">
        <mat-icon class="transaction-action-button-icon" style="color: #00c4c4" aria-hidden="true"
          >sync</mat-icon
        >
      </div>
      Sync All
    </button>
    <button
      class="account-table-action-button tw-mb-5 tw-flex tw-items-center tw-rounded-xl tw-border-none tw-px-10 tw-py-2 tw-shadow-inner"
      type="button"
      (click)="openCreationOptionsModal()"
    >
      <svg class="tw-mr-3 tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#add" />
      </svg>
      Add New
    </button>
  </div>
  <div *ngIf="booksView" class="table-container tw-mb-[100px] tw-flex tw-h-[500px] tw-flex-col">
    <ag-grid-angular
      class="ag-theme-quartz"
      style="height: 100%"
      [columnDefs]="colDefs"
      [rowModelType]="rowModelType"
      [rowSelection]="rowSelection"
      [gridOptions]="gridOptions"
      [context]="context"
      [autoSizeStrategy]="autoSizeStrategy"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)"
      [getContextMenuItems]="getContextMenuItems"
      (cellClicked)="onCellClicked($event)"
    ></ag-grid-angular>
  </div>
</div>
