<app-customer-support-box
  (changeHelpOpen)="handleHelpBoxOpen($event)"
  [helpOpen]="helpOpen"
  [ngClass]="{ 'tw-absolute tw-top-0 tw-bottom-0 tw-left-0 tw-z-[2] tw-flex tw-w-full': helpOpen }"
>
</app-customer-support-box>

<div
  *ngIf="scenarioDifferenceMessage"
  class="tw-flex tw-h-full tw-flex-col tw-items-start tw-justify-between"
>
  <div class="tw-flex tw-flex-col tw-gap-5">
    <h1
      class="tw-font-montserrat tw-text-natural[800] tw-mb-5 tw-flex tw-items-center tw-justify-center tw-text-4xl tw-font-bold xs:tw-text-3xl"
    >
      <svg class="tw-mr-3 tw-h-7 tw-w-7">
        <use [attr.xlink:href]="'images/sprite.svg#' + scenarioDifferenceMessage.headerIcon" />
      </svg>
      {{ scenarioDifferenceMessage.headerText }}!
    </h1>
    <div class="tw-text-[20px]">
      <span class="tw-block">{{ scenarioDifferenceMessage.firstBlock.textOne }}</span>
      <span class="tw-mt-5 tw-block" *ngIf="scenarioDifferenceMessage.firstBlock?.actionButton"
        >Notify our team and we will add the data for you</span
      >
      <span
        (click)="notifyOurTeam()"
        class="tw-mt-5 tw-block tw-cursor-pointer tw-text-lg tw-font-bold tw-leading-[1.25] tw-text-orange-500 tw-underline"
        *ngIf="scenarioDifferenceMessage.firstBlock?.actionButton"
        >{{ scenarioDifferenceMessage.firstBlock.actionButton }}</span
      >
      <span
        class="tw-block tw-text-2xl tw-font-bold"
        *ngIf="scenarioDifferenceMessage.firstBlock.numberToShow"
        >~ {{ scenarioDifferenceMessage.firstBlock.numberToShow | currency: "AUD":"symbol" }}</span
      >
      <span class="tw-block">{{ scenarioDifferenceMessage.firstBlock.textTwo }}</span>
    </div>
    <div class="tw-text-[20px]">
      <span class="tw-block">{{ scenarioDifferenceMessage.secondBlock.textOne }}</span>
      <span
        class="tw-block tw-text-2xl tw-font-bold"
        *ngIf="scenarioDifferenceMessage.secondBlock.numberToShow"
        >{{ scenarioDifferenceMessage.secondBlock.numberToShow | currency: "AUD":"symbol" }}
        <svg class="tw-mr-3 tw-h-7 tw-w-7">
          <use xlink:href="images/sprite.svg#edit-icon" />
        </svg>
      </span>
      <span class="tw-block">{{ scenarioDifferenceMessage.secondBlock.textTwo }}</span>
    </div>
    <div class="tw-text-[20px]">
      <span class="tw-block">{{ scenarioDifferenceMessage.thirdBlock.textOne }}</span>
      <span
        class="tw-block tw-text-2xl tw-font-bold"
        *ngIf="scenarioDifferenceMessage.thirdBlock.numberToShow"
        >~ {{ scenarioDifferenceMessage.thirdBlock.numberToShow | currency: "AUD":"symbol" }}</span
      >
      <span class="tw-block">{{ scenarioDifferenceMessage.thirdBlock.textTwo }}</span>
    </div>
  </div>
  <div class="tw-mt-2.5 tw-flex tw-w-full tw-flex-col tw-justify-between tw-gap-2">
    <div class="tw-w-full">
      <button [class]="classNamesFor.secondaryButton" (click)="balanceEdit()">
        {{ "tryNewBalance" | i18n }}
      </button>
    </div>
    <div class="tw-w-full">
      <button [class]="classNamesFor.button" mat-dialog-close aria-label="Close dialog">
        {{ scenarioDifferenceMessage.buttonText }}
      </button>
    </div>
  </div>
</div>
