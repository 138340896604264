import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

import { IButton } from "./gloss-button.interface";

/**
 * GlossButtonComponent is a reusable button component for the application.
 * It accepts an options object of type GlossButtonOptions as an @Input.
 * The options object can contain iconName, buttonText, and an onClick method.
 *
 * @example
 * <app-gloss-button [options]="{ iconName: 'arrow_back', buttonText: 'Previous', onClick: previous }"></app-gloss-button>
 */

@Component({
  selector: "app-gloss-button",
  templateUrl: "./gloss-button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlossButtonComponent implements OnInit, OnChanges, IButton {
  /**
   * The options object passed to the component. It should be of type GlossButtonOptions.
   * It can contain iconName, buttonText, and an onClick method.
   */

  @Input() options: IButton = {
    class: "primary",
    isEnabled: true,
  };
  isEnabled = true;
  protected isClicked = false;
  className: string;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.className = this.options.class || "primary";
    this.isEnabled = this.options.isEnabled !== undefined ? this.options.isEnabled : true;
    this.options.enableButton = this.enableButton.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.isEnabled = this.options.isEnabled;
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * Method to be called when the button is clicked.
   * If an onClick method is provided in the options object, it will be called.
   */
  onButtonClicked() {
    this.options.type === "submit" && this.enableButton(false); // default behaviour to allow click only once
    this.isClicked = true;
    this.options.onClick();
  }

  // used to enable buttons after confirmation dialogues or validation
  enableButton(value: boolean) {
    this.isEnabled = value;
    this.isClicked = false;
    this.changeDetectorRef.markForCheck();
  }
}
