import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { NavigationEnd, Router } from "@angular/router";
import { map, Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import {
  canAccessAdmin,
  isNotProviderUser,
  OrganizationService,
} from "@bitwarden/common/abstractions/organization/organization.service.abstraction";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { ProviderService } from "@bitwarden/common/abstractions/provider.service";
import { SyncService } from "@bitwarden/common/abstractions/sync/sync.service.abstraction";
import { TokenService } from "@bitwarden/common/abstractions/token.service";
import { devFlagEnabled } from "@bitwarden/common/misc/flags";
import { Organization } from "@bitwarden/common/models/domain/organization";
import { Provider } from "@bitwarden/common/models/domain/provider";
import { glossRoutes } from "@bitwarden/web-vault/app/layouts/navbar.routes";
import { glossRouteInfo } from "@bitwarden/web-vault/app/layouts/sections-info-texts";
import { GlossRouteEnum } from "@bitwarden/web-vault/app/models/enum/navigation.enum";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { GranularityProperty } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { SyncState } from "@bitwarden/web-vault/app/models/types/general-types";
import { GlossRoute } from "@bitwarden/web-vault/app/models/types/navigation.types";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { ColumnStateService } from "@bitwarden/web-vault/app/services/dashboard/columnState-service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { SideMenuService } from "@bitwarden/web-vault/app/services/menu/side-menu.service";
import { GlossSyncService } from "@bitwarden/web-vault/app/services/syncing/gloss-sync.service";
import { SyncStore } from "@bitwarden/web-vault/app/services/syncing/syncing.store";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper.common";

@Component({
  selector: "app-navbar",
  templateUrl: "navbar.component.html",
})
// eslint-disable-next-line rxjs-angular/prefer-takeuntil
export class NavbarComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject<void>();
  protected readonly devFlagEnabled = devFlagEnabled;
  protected readonly GlossRouteEnum = GlossRouteEnum;
  protected readonly HelperCommon = HelperCommon;
  protected readonly RoleScope = RoleScope;
  readonly vm$ = this.syncStore.vm$;

  tooltipInfo: string;
  showHelpTooltip = false;
  xPosition: number;
  yPosition: number;
  pointer: string;
  trianglePointerOffset = 50;
  trianglePointerHeight = 16;
  glossRoute: GlossRoute = null;
  selfHosted = false;
  name: string;
  email: string;
  providers: Provider[] = [];
  userId: string;
  organizations$: Observable<Organization[]>;
  isGlossMenuOpen = true;
  isInfoOpen = false;
  isClicked = false;
  isSyncOpen = false;
  isSyncing = false;
  isAccountsToSync = false;
  syncState: SyncState;
  selectedFilterItem: string;
  granularityOptions: Array<GranularityProperty> = [];
  selectedGranularityOption: string;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild("menuContainer") menuContainer: ElementRef;

  constructor(
    private platformUtilsService: PlatformUtilsService,
    private tokenService: TokenService,
    private providerService: ProviderService,
    private syncService: SyncService,
    private glossSyncService: GlossSyncService,
    private organizationService: OrganizationService,
    private i18nService: I18nService,
    private glossMenuService: SideMenuService,
    private router: Router,
    private bookService: BookService,
    private readonly syncStore: SyncStore,
    private dashboardService: DashboardService,
    private columnStateService: ColumnStateService,
    private renderer: Renderer2
  ) {
    this.listenForGlossRouteChange();

    this.selfHosted = this.platformUtilsService.isSelfHost();
  }

  async ngOnInit() {
    this.dashboardService.granularityOptions$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((options) => {
        this.granularityOptions = options;
        this.selectedGranularityOption = this.dashboardService.granularity;
      });
    this.vm$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state) => {
      this.syncState = state;
      this.isSyncing = state?.isStarted && !state?.isCompleted;
    });

    this.name = await this.tokenService.getName();
    this.email = await this.tokenService.getEmail();
    this.userId = await this.tokenService.getUserId();
    this.columnStateService.setUserId(this.userId);
    if (this.name == null || this.name.trim() === "") {
      this.name = this.email;
    }

    // Ensure providers and organizations are loaded
    if ((await this.syncService.getLastSync()) == null) {
      await this.syncService.fullSync(false);
    }
    this.isAccountsToSync = await this.bookService.hasAutoBooks();
    this.providers = await this.providerService.getAll();

    this.organizations$ = this.organizationService.organizations$.pipe(
      map((orgs) => orgs.filter(isNotProviderUser)),
      canAccessAdmin(this.i18nService)
    );

    this.glossMenuService.isGlossMenuOpen$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isOpen) => (this.isGlossMenuOpen = isOpen));

    this.matMenuOpenAction();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleClick(e: MouseEvent) {
    this.isClicked = !this.isClicked;
  }

  onTooltipClose() {
    this.showHelpTooltip = false;
    this.isInfoOpen = false;
    this.isClicked = !this.isClicked;
  }

  async openDashboardHelp(e: MouseEvent) {
    this.showHelpTooltip = true;
    this.isInfoOpen = true;
    const target = e.currentTarget as HTMLElement;
    const targetRect = target.getBoundingClientRect();

    // this.pointer = "top"; if no error delete by end of september
    this.pointer = "modal";
    this.xPosition = targetRect.right - this.trianglePointerOffset - targetRect.width / 2;
    this.yPosition = targetRect.bottom + this.trianglePointerHeight;
  }

  /**
   * Listens for changes in the router events and updates the current gloss route accordingly.
   *
   * This method subscribes to the router events and filters out events that are not instances of NavigationEnd.
   * When a NavigationEnd event occurs, it updates the current gloss route based on the URL of the event.
   * And finally based on the glossRoute it updates the header in the body of the page.
   */
  listenForGlossRouteChange() {
    this.router.events
      .pipe(
        // Filter out events that are not NavigationEnd events
        filter((event) => event instanceof NavigationEnd),
        // Automatically unsubscribe when the component is destroyed
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event: NavigationEnd) => {
        // Cast the URL of the event to a GlossRouteEnum
        const url: GlossRouteEnum = event.url as GlossRouteEnum;
        // Update the current gloss route based on the URL
        this.showHelpTooltip = false;
        this.glossRoute = glossRoutes[url];
        this.tooltipInfo = <string>glossRouteInfo[url];
      });
  }

  onFilterMenuClick(item: string) {
    this.selectedFilterItem = item;
  }

  async updateSelectedGranularityOption(value: GranularityProperty) {
    this.selectedGranularityOption = value;
    await this.dashboardService.updateGranularity(value);
  }

  matMenuOpenAction() {
    this.menuTrigger.menuOpened.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.renderer.removeClass(this.menuContainer.nativeElement, "tw-rounded-full");
      this.renderer.addClass(this.menuContainer.nativeElement, "tw-rounded-lg");
    });

    this.menuTrigger.menuClosed.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.renderer.removeClass(this.menuContainer.nativeElement, "tw-rounded-lg");
      this.renderer.addClass(this.menuContainer.nativeElement, "tw-rounded-full");
    });
  }

  toggleSync(isSyncOpen?: boolean) {
    if (isSyncOpen !== undefined) {
      this.isSyncOpen = isSyncOpen;
    } else {
      this.isSyncOpen = !this.isSyncOpen;
    }
  }
}
